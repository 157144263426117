<template>
  <div class="default-value">
    <template v-if="isNumber">
      <a-input-number
        @change="changeValue"
        :controls="false"
        v-model:value="inputValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :allowClear="clearable"
        :step="1"
        :step-strictly="isInteger"></a-input-number>
    </template>
    <template v-else-if="isBoolean">
      <a-select
        @change="changeValue"
        :allowClear="clearable"
        :disabled="disabled"
        v-model:value="inputValue"
        :options="options"
        :placeholder="placeholder">
      </a-select>
    </template>
    <template v-else>
      <a-input
        v-model:value="inputValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :allowClear="clearable"
        @change="changeValue"
      ></a-input>
    </template>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'

export default {
  name: 'index',
  props: {
    inputType: {
      type: String,
      default: 'string'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      inputValue: '',
      options: [
        {
          label: 'true',
          value: true
        },
        {
          label: 'false',
          value: false
        }
      ]
    })

    const isNumber = computed(() => props.inputType === 'number' || props.inputType === 'integer')
    const isInteger = computed(() => props.inputType === 'integer')
    const isBoolean = computed(() => props.inputType === 'boolean')

    if (isNumber.value || isInteger.value) {
      state.inputValue = props.value || undefined
    } else if (isBoolean.value) {
      state.inputValue = (props.value !== null && props.value !== undefined && props.value !== '') ? props.value : null
    } else {
      state.inputValue = props.value
    }
    emit('update:value', state.inputValue)

    // watch(() => state.inputValue, () => {
    //   emit('update:value', state.inputValue)
    // })

    const clearInputValue = () => {
      state.inputValue = undefined
    }

    const changeValue = e => {
      emit('update:value', state.inputValue)
    }

    return {
      ...toRefs(state),
      isNumber,
      isInteger,
      isBoolean,
      changeValue,
      clearInputValue
    }
  }
}
</script>

<style scoped lang="less">
.default-value {
  .ant-select {
    :deep .el-input {
      width: 100%;
    }
  }

  :deep .el-input-number {
    .el-input {
      input {
        text-align: left;
      }
    }
  }
}
</style>
