import { HttpServer } from '@/http';
import { API_PROXY } from './commonProxy'
class JoinTeamManage {
  root: HttpServer
  moduleName: string
  constructor(root: HttpServer) {
    this.moduleName = 'goodsManage'
    this.root = root
  }

  // 是否是新用户、是否访问引导页
  getNewUser(userId) {
    return this.root.request({
      url: `${API_PROXY}/api/PersonalCenter/page_smUser/guide/${userId}`,
      method: 'post'
    })
  }

  // 是否是新用户、是否访问引导页
  updateUser(userId) {
    return this.root.request({
      url: `${API_PROXY}/api/PersonalCenter/page_smUser/guide/${userId}`,
      method: 'put'
    })
  }

  //搜索团队
  searchTeam(data = {}) {
    return this.root.request({
      // url: 'http://192.168.0.135:8090/api/AppMarket/page_smTenantCenter/find/',
      url: `${API_PROXY}/api/PersonalCenter/page_smTenantCenter/find/`,
      method: 'put',
      data
    })
  }

  //加入团队
  toJoinTeam(data = {}) {
    return this.root.request({
      // url: 'http://192.168.0.135:8090/api/AppMarket/page_smTenantCenter/find/',
      url: `${API_PROXY}/api/PersonalCenter/page_smTenantCenter/apply/`,
      method: 'post',
      data
    })
  }

  //创建团队
  createTeam(type, data = {}) {
    return this.root.request({
      // url: 'http://192.168.0.135:8090/api/AppMarket/page_smTenantCenter/find/',
      url: `${API_PROXY}/api/PersonalCenter/page_smTenantCenter/${type}/`,
      method: 'post',
      data
    })
  }

  // 获取行业列表
  getIndustry(data) {
    return this.root.request({
      url: `${API_PROXY}/api/PersonalCenter/page_smTenantCenter/getIndustry/`,
      method: 'put',
      data
    })
  }

  //创建团队
  createCompany(data = {}) {
    return this.root.request({
      url: `${API_PROXY}/api/PersonalCenter/page_smTenantCenter/`,
      method: 'post',
      data
    })
  }
}

export default JoinTeamManage
