import { createStore } from 'vuex'
import testModule from './modules/testModule'
import editor from './modules/editor'
import jsonSchema from './modules/jsonSchema'

import getters from './getter'

export default createStore({
  modules: {
    testModule,
    jsonSchema,
    editor
  },
  getters: {
    ...getters
  }
})
