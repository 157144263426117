export interface IFormState {
  groupName: String
  remark: String
  sortRule: String
}
export interface ITree {
  id: String
  itemKey: String
  itemName: String
  itemUrl: String
  itemGroup: String
  seq: null
  formId: null
  remark: null
  usable: Number
  parentId: null
  itemNameParent: null
  useAppUrl: Number
  fullUrl: String
  creatorName: null
  supportClient: Number
  modifyTime: null
  // childList: ITree
}

export interface IObject {
  [key: string]: any
}
export interface IState {
  formState: IFormState
  visible: Boolean
  getTreeList: IObject
  cloneTreeList: IObject
  treeItem: IObject
  treeType: Number
  currentNode: IObject
}



export const getTreeList = [
  {
    "id": "01",
    "tenantId": "1",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "系统管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "0",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-0",
    "show": false,
  },
  {
    "id": "011",
    "tenantId": "31",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "用户管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "01",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-0-0",
    "show": false
  },
  {
    "id": "012",
    "tenantId": "31",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "财务管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "01",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-0-1",
    "show": false
  },
  {
    "id": "013",
    "tenantId": "32",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "权限管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "01",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-0-2",
    "show": false
  },
  {
    "id": "02",
    "tenantId": "2",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "应用管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "0",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-1",
    "show": false,
  },
  {
    "id": "021",
    "tenantId": "3",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "销售管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "03",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-1-0",
    "show": false,
  },
  {
    "id": "0211",
    "tenantId": "4",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "坯布管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "021",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-1-0-0",
    "show": false
  },
  {
    "id": "0212",
    "tenantId": "5",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "染布管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "021",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-1-0-1",
    "show": false
  },
  {
    "id": "03",
    "tenantId": "6",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "产品管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "0",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-2",
    "show": false,
  },
  {
    "id": "031",
    "tenantId": "7",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "物料管理",
    "groupCode": "8",
    "basePath": "testDb",
    "parentId": "03",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-2-0",
    "show": false,
  },
  {
    "id": "0311",
    "tenantId": "9",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "坯布管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "031",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-2-0-0",
    "show": false
  },
  {
    "id": "0312",
    "tenantId": "00",
    "appId": "1",
    "appCode": "1",
    "dateResourceId": "0",
    "groupName": "染布管理",
    "groupCode": "1523560184339894272",
    "basePath": "testDb",
    "parentId": "031",
    "level": "0",
    "remark": "",
    "usable": true,
    "grouplist": null,
    "type": null,
    "key": "0-2-0-1",
    "show": false
  }
]

// 扁平化树形结构
export const setArray = (data) => {
  let array: any = []
  const run = (arr) => {
    arr.forEach(element => {
      array.push(element)
      if (element?.children?.length) {
        array = run(element.children)
      }
    });
    return array
  }
  run(data)
  return array
}

// 组装树形结构
export const setTree = (data) => {
  const array = []
  const obj = {}
  data.forEach(element => {
    obj[element.id] = element
  });
  data.forEach(element => {
    const parent = obj[element.parentId]
    element.children = element.children || []
    element.basePath = element.basePath || ""
    if (parent) {
      parent.children = parent.children || []
      parent.children.push(element)
    } else {
      // * 当前项没有父节点 -> 顶层
      array.push(element)
    }
  });
  return array
}

// 对象数组中查找符合目标对象
export const parseArray = (objArray, key, value) => {
  for (let i in objArray) {
    const element = objArray[i];
    if (typeof element === 'object') {
      let result = parseArray(element, key, value)
      if (result) return result
    } else {
      if (i === key) {
        if (element === value) return objArray
      }
    }
  }
}