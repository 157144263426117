import { HttpServer } from '@/http'
import { API_PROXY } from './commonProxy'
import { IApiOptions } from '@/types'


class PageManage {
  root: HttpServer
  moduleName: string

  constructor(root: HttpServer) {
    this.root = root
  }

  //页面管理弹框 新增
  createPageInfo(data) {
    return this.root.request({
      url: `${API_PROXY}/api/manager/webPage/`,
      method: 'post',
      data
    })
  }

  //页面管理弹框 保存
  savePageInfo(id, data) {
    return this.root.request({
      url: `${API_PROXY}/api/manager/webPage/${id}`,
      method: 'put',
      data
    })
  }


  // 保存配置
  savePageConfig(data) {
    return this.root.request({
      url: `${API_PROXY}/api/pageControl/`,
      method: 'post',
      data
    })
  }

  // 查询配置
  getPageInfo(pageId, token, params) {
    return this.root.request({
      url: `${API_PROXY}/api/webPage/getAppId/${pageId}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params
    })
  }

  // 查询配置
  getPageData(data) {
    return this.root.request({
      url: `${API_PROXY}/api/pageControl/${data}`,
      method: 'GET'
    })
  }

  // 查询组件列表
  getPageList() {
    return this.root.request({
      url: `${API_PROXY}/api/pageUnit/`,
      method: 'GET'
    })
  }

  // 保存页面
  savePage(id, data) {
    return this.root.request({
      url: `${API_PROXY}/api/webPage/${id}`,
      method: 'POST',
      data
    })
  }
  editPageInfo(id, data) {
    return this.root.request({
      url: `${API_PROXY}/api/webPage/${id}`,
      method: 'PUT',
      data
    })
  }

  // 提交页面
  submitPage(id) {
    return this.root.request({
      url: `${API_PROXY}/api/webPage/submit/${id}`,
      method: 'post',
    })
  }

  //获取页面标签列表
  getTagList() {
    return this.root.request({
      url: `${API_PROXY}/api/manager/webPageGroup/list/`,
      method: 'GET'
    })
  }

  //更新接口数据
  updateChartsData(apiOptions: IApiOptions) {
    const { url, method, headers, params } = apiOptions
    return this.root.request({
      url,
      method,
      headers,
      data: params
    })
  }

  //保存API配置
  saveOrUpdate(data) {
    return this.root.request({
      url: `${API_PROXY}/api/webPageControlApi/saveOrUpdate`,
      method: 'post',
      data
    })
  }

  //请求用户配置的API接口
  requestApi(data) {
    return this.root.request({
      url: `${API_PROXY}/api/webPageControlApi/requestApi`,
      method: 'post',
      data
    })
  }

  //查询 租户数据源列表
  getDataResource() {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/list`,
      method: 'GET'
    })
  }

  //查询 租户API 标签 列表
  getApiTagList() {
    return this.root.request({
      url: `${API_PROXY}/api/apiTag/list`,
      method: 'GET'
    })
  }

  // 查询动态API列表
  getDyncApiList(params) {
    return this.root.request({
      url: `${API_PROXY}/api/dyncApi/page`,
      method: 'GET',
      params
    })
  }


  // 上传图片
  uploadBackgroundImage(data, pageId) {
    return this.root.request({
      url: `${API_PROXY}/api/uploadFile/${pageId}`,
      method: 'post',
      data
    })
  }


  getGroupId(groupName: string) {
    return this.root.request({
      url: `${API_PROXY}/api/apiGroup/byName/${groupName}`,
      method: 'get'
    })
  }

  getGroupListFromDyncApi(params) {
    return this.root.request({
      url: `${API_PROXY}/api/dyncApi`,
      method: 'get',
      params
    })
  }

  exportPage(data: {
    ids: string[]
  }) {
    return this.root.request({
      url: `${API_PROXY}/api/webPage/export`,
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
}

export default PageManage
