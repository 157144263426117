import apiManage from '@/views/homeCatalogue/api-manage/index.vue'
import apiServiceDetail from '@/views/homeCatalogue/api-manage/api-service-detail/index.vue'
import dbServiceDetail from '@/views/homeCatalogue/api-manage/db-service-detail/index.vue'
import Layout from '@/layout/index.vue'

export default {
  path: '/api-manages',
  name: 'apiManage',
  component: Layout,
  redirect: '/api-manage',
  meta: {
    title: '数据源管理',
  },
  children: [
    {
      path: '/api-manage',
      name: 'api-manage',
      component: apiManage,
      meta: {
        hidden:true,
        title: '数据源管理',
        icon: 'icon-api-manage',
        keepAlive: true,
      },
    },
    {
      hidden: true,
      path: '/service-detail',
      name: 'service-detail',
      component: apiServiceDetail,
      meta: {
        hidden: true,
        keepAlive: true,
      },
    },
    {
      hidden: true,
      path: '/db-service-detail',
      name: 'db-service-detail',
      component: dbServiceDetail,
      meta: {
        hidden: true,
        keepAlive: true,
      },
    },
  ],
}
