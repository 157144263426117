import { message } from 'ant-design-vue'

export const checkJsonString = value => {
  if (!value) {
    message.error('导入JSON不得为空')
    return false
  }
  let _value
  try {
    _value = JSON.parse(value)
  } catch (err) {
    message.error('JSON格式不正确')
    return false
  }
  return _value
}

export const uuid = () => {
  return Math.random()
    .toString(16)
    .substr(2, 5)
}

export const formatJsonStr = (data, replacer = null, space = 2) => {
  if (typeof data !== 'string') return data
  let res
  try {
    const temp = JSON.parse(data)
    res = JSON.stringify(temp, replacer, space)
  } catch (e) {
    res = data
  }
  return res
}
