import {
  defineComponent,
  reactive,
  computed,
  onMounted,
  onUnmounted,
  getCurrentInstance,
  ref
} from 'vue'
import { Button, message, Modal, Textarea,Form,Input,FormItem, Tree, TreeNode } from 'ant-design-vue'
import './index.less'
import { IState, ITree, getTreeList, IFormState, setTree, setArray, parseArray } from './types'
import { EditOutlined, PlusOutlined} from '@ant-design/icons-vue';
import API from '@/api'
import { nextTick } from 'process';
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'ComGrouping',
  props: {
    fieldNames: {
      type: Object ,
      default:() => ({
        children: 'children',
        title: 'groupName',
        key:"sortRule"
      })
    },
    labelCol:{
      type: Object ,
      default:() => ({
        span: 5
      })
    }
  },
  setup (props, {emit}) {
    const route = useRoute()
    const formRef = ref();
    const rules:any ={
      groupName: [
        { required: true, message: '请输入分组名称', trigger: 'blur' },
        { min: 1, max: 20, message: '分组名称不得超过20字', trigger: 'blur' },
      ],
    } 
    const gData = ref<any>([]);
    const expandedKeys = ref<any>([]);
    const selectedKeys = ref<any>([]); 
    const selectedKeysClone = ref<any>([]);
    const title = ref<any>("添加分组")

    const state = reactive<IState>({
      currentNode: [],
      formState: {
        groupName: "",
        remark: "",
        sortRule:"0-0"
      },
      visible: false,
      getTreeList: getTreeList,
      cloneTreeList: [],
      treeItem: {},
      treeType: 0, // 0 添加分组 1 是添加子分组 2 是编辑分组
    })

    // 拖拽
    const onDrop = (info: any) => {
      console.log(info,"-----------------------------------------")
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      const loop = (data: any, sortRule: string, callback: any) => {
        data.forEach((item, index, arr) => {
          if (item.sortRule === sortRule) {
            return callback(item, index, arr);
          }
          if (item.children) {
            return loop(item.children, sortRule, callback);
          }
        });
      };
      const data = [...gData.value];
      // Find dragObject
      let dragObj: any = {};
      loop(data, dragKey, (item: any, index: number, arr: any) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, (item: any) => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.push(dragObj);
        });
      } else if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, (item: any) => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.unshift(dragObj);
        });
      } else {
        let ar: any = [];
        let i = 0;
        loop(data, dropKey, (item: any, index: number, arr: any) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      gData.value = data;
      dropUpdateApi(info)
    };
    // 拖拽后保存接口
    const dropUpdateApi =(val)=>{
      const data = {
        id:val.dragNode.id,
        parentId: val.dropToGap ? "0" : val.node.id,
        sortRule: val.dragNode.sortRule
      } 
      API.groupManage.dropUpdate(data).then((res) => {
        getGroup()
      })
    }
    
    const handleNodeClick = (info: any) => {
      if(info.length){
        selectedKeys.value = info
        selectedKeysClone.value = info
      }else{
        selectedKeys.value = selectedKeysClone.value
      }
      if(state.treeType <= 0){
        state.treeItem = parseArray(gData.value, "sortRule", selectedKeys.value[0])
        emit("handleNode", setArray([state.treeItem]))
      }
    };
    // 获取分组
    const getGroup = ()=>{
      API.groupManage.getGroup("").then((res) => {
        gData.value = setTree(res.data)
        state.currentNode = gData.value[0]
        emit("handleNode", setArray([gData.value[0]]))
      })
    }
    // 新增分组
    const newGroup=(item)=>{
      // 如果有 sortRule 
      const str = String(Math.random())
      const sortRule = str.slice(2, str.length)
      const data = {
        ...state.formState,
        parentId: item.id || undefined,
        sortRule: sortRule
      } 
      API.groupManage.newGroup(data).then((res) => {
        getGroup()
      })
    }
    // 编辑分组
    const editGroupApi = (id)=>{
      const data = {
        ...state.treeItem,
        ...state.formState
      } 
      API.groupManage.editGroup(data,id).then((res:any) => {
        if(!res?.success) return
        const obj = parseArray(gData.value, "id", state.treeItem.id)
        obj.groupName = state.formState.groupName
        obj.remark = state.formState.remark
        state.visible = false
      })
    }
    const init = () => {
      // 初始化赋值
      API.groupManage.getGroup("").then((res) => {
        const expandedKeysList = []
        const isGroupId = route.query.groupId
        const data = setTree(res.data)
        if(isGroupId){
          const newArr = setArray([parseArray(data, "id", isGroupId)])
          newArr.forEach(element => {
            expandedKeysList.push(element.sortRule)
          })
          selectedKeys.value = [newArr[0].sortRule]
          selectedKeysClone.value = [res.data[0].sortRule]
          expandedKeys.value = expandedKeysList
        }else{
          setArray([res.data[0]]).forEach(element => {
            expandedKeysList.push(element?.sortRule)
          })
          expandedKeys.value = expandedKeysList
          selectedKeys.value = [res.data[0]?.sortRule]
          selectedKeysClone.value = [res.data[0]?.sortRule]
        }

        gData.value = setTree(res.data)
        state.currentNode = gData.value[0]
        emit("handleNode", setArray([gData.value[0]]))
      })
    }
    onMounted(() => {
      init()
    })
    
    // 添加分组
    const addGroups = () =>{
      state.formState.groupName = ""
      state.formState.remark = ""
      state.visible = true
      state.treeType = 0
      title.value = "添加分组"
    }
    // 添加子分组
    const addGroup = (item, index) =>{
      state.visible = true
      state.treeItem = item
      state.treeType = index
      title.value = "添加分组"
    } 
    // 编辑分组
    const editGroup = (item, index) =>{
      state.visible = true
      state.treeItem = item.data

      state.treeType = index
      state.formState.groupName = item.data.groupName
      state.formState.remark = item.data.remark
      title.value = "编辑分组"
    } 
    // 删除分组 
    const delGroup = () => {
      const data = {
        ids: [state.treeItem.id]
      }
      state.treeItem?.children && setArray(state.treeItem?.children).forEach(element => {
        data.ids.push(element?.id)
      });
      if(!state.treeItem?.children?.length){
        delGroupApi(data)
        return
      }
      Modal.confirm({
        title: () => '删除分组',
        content: () => '删除分组后，分组及其子分组下的数据也会一并删除，是否继续?',
        okText: () => '确定',
        okType: 'danger',
        cancelText: () => '取消',
        onOk() {
          console.log('OK');
          delGroupApi(data)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
    const delGroupApi = (data)=>{
      API.groupManage.delGroup(data).then((res) => {
        console.log(res.data,"删除分组")
        getGroup()
      })
    }
    // 确定弹框
    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          // 添加分组
          if(state.treeType === 0){
            newGroup("")
            state.visible = false
            state.formState.groupName = ""
            state.formState.remark = ""
          }
          
          // 添加子节点
          if(state.treeType === 1){
            const obj = parseArray(gData.value, "key", state.treeItem.key)
            newGroup(state.treeItem)
            state.visible = false
            state.formState.groupName = ""
            state.formState.remark = ""
          }

          // 编辑分组
          if(state.treeType === 2){
            editGroupApi(state.treeItem.id)
          }
          state.treeType=0
        })
        .catch((error:any) => {
          console.log('error', error);
        });
    }
    // 关闭弹框
    const onCancel=()=>{
      state.formState.groupName = ""
      state.formState.remark = ""
      state.treeType = 0
    }
    
    return () => <div class='group'>
      <div class='group-header'>
        <div class='group-header-title'>分组</div>
        <div class='group-header-btn'>
          <Button type="link" onClick={addGroups}>添加</Button>
          <Button type="text" danger onClick={delGroup}>删除</Button>
        </div>
      </div>
      <div class='group-content'>
        {gData.value.length ?
          (<Tree
            class="draggable-tree"
            v-model:expandedKeys={expandedKeys.value}
            v-model:selectedKeys={selectedKeys.value}
            draggable
            blockNode
            treeData={gData.value}
            onSelect={handleNodeClick}
            fieldNames={props.fieldNames}
            onDrop={onDrop}
            v-slots={{
              title: (item) =>
              <div class="tree-item">
                <div class="tree-item-name">
                  {item.groupName}
                </div>
                {
                  item.level < 2 ? 
                  (<div class="tree-item-btn">
                    <EditOutlined class="edit-group" onClick={editGroup.bind(this, item, 2)} />
                    <PlusOutlined class="add-group" onClick={addGroup.bind(this, item, 1)} />
                  </div>) : null
                }
              </div>
            }}
          >
          </Tree>) : 
          (<div class="empty">暂无数据，请先添加分组</div>)
        }
      </div>
    
      <Modal v-model:visible={state.visible} cancelText="取消" okText="确定" title={title.value} onCancel={onCancel} onOk={handleOk}>
        <Form
          ref={formRef}
          model={state.formState}
          rules={rules}
          label-col={props.labelCol}
        >
          {/*    */}
          <FormItem  label="分组名称" name="groupName">
            <Input v-model:value={state.formState.groupName} />
          </FormItem>
          <FormItem label="备注内容" name="desc" style="margin-top: 20px;">
            <Textarea maxlength={300}  v-model:value={state.formState.remark} />
          </FormItem>
        </Form>
      </Modal>
    </div>
  }
})
