<template>
  <div class="velocity-view">
    <a-typography-title :level="5">变量：</a-typography-title>
    <a-typography-text>1、sql语句中使用 <a-typography-text code>#{变量名称}</a-typography-text> 标识为变量</a-typography-text>
    <div class="example">
      <a-typography-paragraph>
        <blockquote>示例：
          <a-typography-text code>select * from smUser where tenant_id = #{tenantId}</a-typography-text>
          变量名为
          <a-typography-text code>tenantId</a-typography-text>
        </blockquote>
      </a-typography-paragraph>
    </div>
    <a-typography-text>2、sql语句字符串中使用非字符串类型的变量时，用
      <a-typography-text code>##{变量名称}</a-typography-text>
      标识为变量
    </a-typography-text>
    <div class="example">
      <a-typography-paragraph>
        <blockquote>示例：
          <a-typography-text code>
            EXEC dbo.sppbQuerySplitPage @sql='select top 12 * from dbo.hrEmployee where sex=##{sex}'
            ,@iPageSize=10,@iCurrPage=1,@bReturnPageInfo=1
          </a-typography-text>
          ，其中sex为字符串中的变量名称
        </blockquote>
      </a-typography-paragraph>
    </div>
    <a-typography-text>3、sql语句字符串中使用字符串类型变量时 需要用两个单引号包裹变量
      <a-typography-text code>''##{变量名称}''</a-typography-text>
    </a-typography-text>
    <div class="example">
      <a-typography-paragraph>
        <blockquote>示例：
          <a-typography-text code>
            EXEC dbo.sppbQuerySplitPage @sql='select top 12 * from dbo.hrEmployee where department_name =
            ''##{department_name}''' ,@iPageSize=2,@iCurrPage=#{iCurrPage},@bReturnPageInfo=1
          </a-typography-text>
          ， 其中
          <a-typography-text code>department_name</a-typography-text>
          为字符串中的字符串类型变量名
        </blockquote>
      </a-typography-paragraph>
    </div>

    <a-typography-title :level="5">模板：</a-typography-title>
    <a-typography-paragraph>使用模板语句之前必须打开"是否使用模板"开关,模板支持
      <a-typography-text code>#set</a-typography-text>
      、
      <a-typography-text code>#if</a-typography-text>
      、
      <a-typography-text code>#else</a-typography-text>
      、
      <a-typography-text code>#end</a-typography-text>
      、
      <a-typography-text code>#foreach</a-typography-text>
      等关键字
    </a-typography-paragraph>
    <a-typography-paragraph>
      <ul>
        <li>
          <a-typography-link>if关键字</a-typography-link>
          <a-typography-paragraph>
            <blockquote>示例：
              <a-typography-text code>
                SELECT id , group_name ,group_code,base_path FROM dync_api_group WHERE tenant_id = #{tenant_id}
                #if(##{groupName}) and group_name = '##{groupName}' #end #if(##{sex}) and sex = ##{sex} #end
              </a-typography-text>
              ，其中变量
              <a-typography-text code>##{groupName}</a-typography-text>
              是字符串类型需要用单引号包裹
            </blockquote>
          </a-typography-paragraph>
        </li>
        <li>
          <a-typography-link>foreach关键字</a-typography-link>
          <a-typography-paragraph>
            <blockquote>示例：
              <a-typography-text code>
                SELECT id , group_name ,group_code,base_path FROM dync_api_group WHERE 1=1 and id in
                (#foreach($id in ##{idList})##{id},#end 0)
              </a-typography-text>
              ，
              <a-typography-text code>idList</a-typography-text>
              设置值为:
              <a-typography-text code>1,2,3</a-typography-text>
              ，其类型为字符串时设置值为:
              <a-typography-text code>'参数值1'</a-typography-text>
              <a-typography-text code>'参数值2'</a-typography-text>
              <a-typography-text code>'参数值3'</a-typography-text>
              。
              <a-typography-text code>id</a-typography-text>
              参数不用传值。
            </blockquote>
          </a-typography-paragraph>
        </li>
      </ul>
    </a-typography-paragraph>
    <a-typography-paragraph>
      模板使用的是Velocity模板引擎，需要了解更多的使用方法，访问
      <a href="https://codingdict.com/article/23731" target="_blank">https://codingdict.com/article/23731</a>查看
    </a-typography-paragraph>
  </div>
</template>
<style scoped lang="less">
.velocity-view {
  background: #f7f7f7;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 6px;

  .example {
    padding-left: 16px;
  }
}
</style>
