/**
 * 复杂表格选择类型
 */
export enum ChooseType {
  None = 0,
  CheckBok = 1,
  Radio = 2,
}

/**
 * 复杂表格 --> 自定义process颜色
 */
export enum ProcessColor {
  DANGER = '#ff3535',
  WARNING = 'linear-gradient(270deg,#f9d04d 1%, rgba(85,72,21,0.07))',
  SUCCESS = 'linear-gradient(270deg,#81ff9a, rgba(40,51,75,0.07))',
  DEFAULT = 'linear-gradient(270deg,#6097fc, rgba(40,51,75,0.07))',
}

/**
 * 页面类型
 */
export enum PageType {
  View = 'view',
  Edit = 'edit',
  Add = 'add',
  Copy = 'copy',
}

/**
 * API 请求类型 字符串
 */
export enum RequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

/**
 * API Request参数类型
 */
export enum RequestParamsType {
  Body = 'Body',
  Query = 'Query',
  Headers = 'Headers',
  Rest = 'Rest'
}

/**
 * API 请求类型
 */
export enum RequestModel {
  GET = '0',
  POST = '1',
  PUT = '2',
  DELETE = '3',
}

/**
 * API状态
 * 0 未提交 1 已提交
 */

export enum APIStatus {
  Uncommitted = '0',
  Committed = '1'
}

/**
 * 任务类型
 */
export enum ServiceType {
  API = 1,
  DB = 2,
}

/**
 * 是否开启响应结果转换规则(0否 1是)
 */
export enum OpenResponseTransfer {
  Close = '0',
  Open = '1'
}

/**
 * 条件
 */
export enum Condition {
  All = 'and',
  Any = 'or'
}

/**
 * 运算符号
 */
export enum ComputeSign {
  Equal = '=',
  NotEqual = '!=',
  Include = 'like',
  Exclude = 'not like',
  IsNull = 'is null',
  IsNotNull = 'is not null',
}

/**
 * 轮播管理 - groupType
 */
export enum CarouselGroupType {
  BoardBi = 1
}

/**
 * 轮播管理 - groupPageType
 * 分组1、页面2
 */
export enum CarouselGroupPageType {
  Group = 1,
  Page = 2
}

/**
 * 轮播管理 - 看板发布状态
 */
export enum CarouselBiReleaseStatus {
  NotRelease = 0
}

/**
 * 轮播管理 - 参数类型 
 * 1 通用参数 
 * 2 固定参数
 */
export enum CarouselBiParamType {
  Common = 1,
  Fixed = 2
}

/**
 * 大屏设计器 - 发布状态
 * 草稿0、已发布1
 */
export enum LargeScreenDesignReleaseStatus {
  Draft = 0,
  Released = 1
}


/**
 * 大屏设计器 - 发布状态
 * 草稿0、最新的版本1
 */
export enum LargeScreenDesignPublishStatus {
  Draft = 0,
  Latest = 1
}