<template>
  <div class="array-type">
    <a-row align="middle">
      <!--参数名称 + 是否必要-->
      <a-col
        :span="nameSpanNum"
        :style="tagPaddingLeftStyle"
        class="col-item name-item col-item-name"
      >
        <a-row align="middle" justify="space-around">
          <a-col :span="2" class="down-style-col">
            <div @click="handleClickIcon" v-if="items.type === 'object'" class="down-style">
              <CaretRightOutlined v-if="hiddenIcon"/>
              <CaretDownOutlined v-else/>
            </div>
          </a-col>
          <a-col :span="22">
            <div class="name-input-row">
              <a-input disabled value="Items"/>
              <a-tooltip
                title="全选"
                placement="top"
                class="check-all show-all">
                <a-checkbox v-model:checked="checked" disabled/>
              </a-tooltip>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <!--类型-->
      <a-col :span="spanNum" class="col-item">
        <InputView
          select
          :value="items.type"
          placeholder="类型"
        >
          <a-select placeholder="类型" v-model:value="items.type" @change="handleChangeType">
            <a-select-option
              :key="item"
              :label="item"
              :value="item"
              v-for="item in SCHEMA_TYPE"
            ></a-select-option>
          </a-select>
        </InputView>
      </a-col>
      <template v-if="showAll">
        <!--格式-->
        <a-col :span="spanNum" class="col-item col-item-format">
          <InputView
            select
            :value="items.format"
            placeholder="格式"
            :disabled="formatDisable"
          >
            <a-select
              :disabled="formatDisable"
              v-model:value="items.format"
              allowClear
              placeholder="格式"
            >
              <a-select-option
                :key="item"
                :label="item"
                :value="item"
                v-for="item in SCHEMA_FORMAT"
              ></a-select-option>
            </a-select>
          </InputView>
        </a-col>
        <!--最小长度-->
        <a-col :span="spanNum" class="col-item">
          <InputView
            :value="items.minLength"
            :disabled="lengthDisable"
            placeholder="最小长度"
          >
            <a-input-number
              :controls="false"
              :disabled="lengthDisable"
              :step="2"
              @blur="checkMin(true)"
              placeholder="最小长度"
              v-model:value="items.minLength"
            ></a-input-number>
          </InputView>
        </a-col>
        <!--最大长度-->
        <a-col :span="spanNum" class="col-item">
          <InputView
            :value="items.maxLength"
            :disabled="lengthDisable"
            placeholder="最大长度"
          >
            <a-input-number
              :controls="false"
              :disabled="lengthDisable"
              :min="items.minLength"
              :step="2"
              placeholder="最大长度"
              v-model:value="items.maxLength"
            ></a-input-number>
          </InputView>
        </a-col>
        <!--最小值-->
        <a-col :span="spanNum">
          <InputView
            :value="items.min"
            :disabled="numberDisable"
            placeholder="最小值"
          >
            <a-input-number
              :controls="false"
              :disabled="numberDisable"
              :min="-9007199254740992"
              @blur="checkMin(false)"
              placeholder="最小值"
              v-model:value="items.min"
            ></a-input-number>
          </InputView>
        </a-col>
        <!--最大值-->
        <a-col :span="spanNum">
          <InputView
            :value="items.max"
            :disabled="numberDisable"
            placeholder="最大值"
          >
            <a-input-number
              :controls="false"
              :disabled="numberDisable"
              :max="9007199254740992"
              placeholder="最大值"
              v-model:value="items.max"
            ></a-input-number>
          </InputView>
        </a-col>
        <!--值列表-->
        <a-col :span="spanNum" class="col-item value-list">
          <a-input-group compact>
            <a-input :disabled="valueListDisable" v-model:value="items.valueList" placeholder="值列表"/>
            <a-button @click="showValueList" :disabled="valueListDisable">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </a-input-group>
        </a-col>
      </template>
      <!--标题-->
      <a-col :span="spanNum" class="col-item">
        <InputView
          :value="items.title"
          placeholder="标题"
        >
          <a-input placeholder="标题" v-model:value="items.title"></a-input>
        </InputView>
      </a-col>
      <!--默认值-->
      <a-col :span="spanNum" class="col-item" v-if="showAll">
        <InputView
          :disabled="defaultValueDisable"
          :value="items.defaultValue"
          placeholder="默认值"
        >
          <DefaultValue
            ref="defaultValueRef"
            :disabled="defaultValueDisable"
            :inputType="items.type"
            v-model:value="items.defaultValue"
            placeholder="默认值"
          ></DefaultValue>
        </InputView>
      </a-col>

      <!--操作-->
      <a-col :span="actionSpanNum" class="col-item col-item-setting">
        <a-tooltip title="添加子节点" placement="top" v-if="items.type === 'object'">
          <a-button type="link" @click="handleAdd">
            <template #icon>
              <PlusSquareOutlined/>
            </template>
          </a-button>
        </a-tooltip>
      </a-col>
    </a-row>
    <div class="option-formStyle">
      <template v-if="items.type === 'array'">
        <SchemaArray
          :data="items"
          :editor-id="editorId"
          :prefix="prefixArray"
          :showAll="showAll"
        />
      </template>
      <template v-if="items.type === 'object'">
        <SchemaObject
          ref="schemaObjRef"
          v-show="!hiddenIcon"
          :data="items"
          :editor-id="editorId"
          :prefix="nameArray"
          :showAll="showAll"
        />
      </template>
    </div>
    <ValueListDialog v-model:visible="showValueListDialog" v-model:value="items.valueList"/>
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs, watch } from 'vue'
import DefaultValue from './components/DefaultValue'
import SchemaObject from './SchemaObject'
import ValueListDialog from '../ValueListDialog'
import { defaultSchema, SCHEMA_FORMAT, SCHEMA_TYPE } from '../../utils/const'
import { uuid } from '../../utils/tools'
import { CaretDownOutlined, CaretRightOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash'
import { useStore } from 'vuex'
import InputView from './components/InputView/index.vue'

export default {
  name: 'SchemaArray',
  components: {
    DefaultValue,
    SchemaObject,
    ValueListDialog,
    InputView,
    CaretDownOutlined, CaretRightOutlined, PlusSquareOutlined, EditOutlined,
  },
  props: {
    showAll: { type: Boolean, default: true },
    editorId: { type: String, default: 'editor_id' },
    name: { type: String, default: '' },
    prefix: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {
      }
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const defaultValueRef = ref()
    const schemaObjRef = ref()
    const state = reactive({
      checked: true,
      hiddenIcon: true,
      tagPaddingLeftStyle: {},
      items: props.data.items || {},

      SCHEMA_TYPE,
      SCHEMA_FORMAT,
      showValueListDialog: false,
    })

    const isShow = computed(() => !!Object.keys(state.items).length)
    const nameSpanNum = computed(() => props.showAll ? 4 : 8)
    const actionSpanNum = computed(() => props.showAll ? 2 : 4)
    const spanNum = computed(() => props.showAll ? 2 : 4)
    const nameArray = computed(() => [...prefixArray.value, 'properties'])
    const prefixArray = computed(() => [...props.prefix, 'items'])
    const formatDisable = computed(() => !['string', 'number', 'integer'].includes(state.items.type))
    const lengthDisable = computed(() => state.items.type !== 'string')
    const numberDisable = computed(() => !['number', 'integer'].includes(state.items.type))
    const valueListDisable = computed(() => !['string', 'number', 'integer'].includes(state.items.type))
    const defaultValueDisable = computed(() => ['object', 'array'].includes(state.items.type))


    const syncItems = () => {
      state.items = props.data.items || cloneDeep(defaultSchema.string)
    }

    const handleClickIcon = () => {
      state.hiddenIcon = !state.hiddenIcon
    }

    //校验大小值
    const checkMin = (isLength) => {
      const { maxLength, minLength, max, min } = state.items
      if (isLength) {
        if (!maxLength || maxLength < minLength) {
          state.items.maxLength = minLength
        }
      } else {
        if (!max || max < min) {
          state.items.max = min
        }
      }
    }

    //显示 值列表
    const showValueList = () => {
      if (valueListDisable.value) return
      state.showValueListDialog = true
    }

    const handleAdd = () => {
      const newName = 'field_' + uuid()
      props.data.items.properties[newName] = cloneDeep(defaultSchema.string)
      schemaObjRef.value?.handleAdd({ newName })
      store.commit('jsonSchema/CALL_SCROLL_FUNCTION', props.editorId)
    }

    //改变类型
    const handleChangeType = (value) => {
      props.data.items = cloneDeep(defaultSchema[value])
      syncItems()
    }

    const init = () => {
      syncItems()
      const length = props.prefix.filter(name => name !== 'properties').length
      state.tagPaddingLeftStyle = {
        paddingLeft: `${10 * (length + 1)}px`
      }
    }
    init()

    return {
      defaultValueRef,
      schemaObjRef,
      ...toRefs(state),
      handleClickIcon,
      isShow,
      nameArray,
      prefixArray,
      nameSpanNum,
      actionSpanNum,
      spanNum,
      formatDisable,
      numberDisable,
      lengthDisable,
      valueListDisable,
      defaultValueDisable,
      handleAdd,
      checkMin,
      handleChangeType,
      showValueList,
    }

  }
}
</script>

<style scoped>

</style>
