import { set, unset } from 'lodash'

export interface IStoreState {
  jsonSchemaScroll: Record<string, any>
  allRequiredMap: Record<string, boolean>
}

const state: IStoreState = {
  jsonSchemaScroll: {},
  allRequiredMap: {},
}

const mutations = {
  HANDLE_CHECK_ALL: (state: IStoreState, data: Record<string, any>) => {
    const { id, value } = data
    if (typeof value !== 'boolean') return
    state.allRequiredMap = {
      ...state.allRequiredMap,
      [id]: value
    }
  },
  CLEAR_ALL_REQUIRED: (state: IStoreState, id: string) => {
    unset(state.allRequiredMap, [id])
  },
  SET_SCROLL_FUNCTION: (state: IStoreState, { id, func }: Record<string, any>) => {
    state.jsonSchemaScroll = {
      ...state.jsonSchemaScroll,
      [id]: func
    }
  },
  CALL_SCROLL_FUNCTION: (state: IStoreState, id: string) => {
    const func = state.jsonSchemaScroll[id]
    func && func()
  },
  CLEAR_SCROLL_FUNCTION: (state: IStoreState, id: string) => {
    unset(state.jsonSchemaScroll, [id])
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
