/**
 * @description 获取旧的模板json
 * @param pageID
 * @param pageName
 * @param pageEname
 * @returns
 */
export const getTemplateJSON = (pageID, pageName, pageEname) => {
  return {
    pageID: pageID || '',
    pageName: pageName,
    isDark: true,
    body: {
      type: '',
      style: {
        width: '1920',
        height: '1080',
        background: '',
        backgroundImage: '',
        backgroundSize: '',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '',
        backgroundOpacity: '',
        backgroundBlendMode: '',
        backgroundClip: '',
        backgroundOrigin: '',
      },
      children: [],
    },
    pageEname: pageEname,
    pageDataSource: '0',
  }
}

/**
 * @description 获取新的模板json
 * @returns
 */
export const getTemplateJSONNew = () => {
  return {
    dataSource: [],
    editCanvasConfig: {
      width: 1920,
      height: 1080,
      filterShow: false,
      hueRotate: 0,
      saturate: 1,
      contrast: 1,
      brightness: 1,
      opacity: 1,
      rotateZ: 0,
      rotateX: 0,
      rotateY: 0,
      skewX: 0,
      skewY: 0,
      blendMode: 'normal',
      selectColor: true,
      chartThemeColor: 'dark',
      chartThemeSetting: {
        title: {
          show: true,
          textStyle: {
            color: '#BFBFBF',
            fontSize: 18,
          },
          subtextStyle: {
            color: '#A2A2A2',
            fontSize: 14,
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            name: '',
            nameGap: 15,
            nameTextStyle: {
              color: '#B9B8CE',
              fontSize: 12,
            },
            inverse: false,
            axisLabel: {
              show: true,
              fontSize: 12,
              color: '#B9B8CE',
              rotate: 0,
            },
            position: 'bottom',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#B9B8CE',
                width: 1,
              },
              onZero: true,
            },
            axisTick: {
              show: true,
              length: 5,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#484753',
                width: 1,
                type: 'solid',
              },
            },
          },
        ],
        yAxis: [
          {
            min: null,
            max: null,
            type: 'value',
            show: true,
            name: '',
            nameGap: 15,
            nameTextStyle: {
              color: '#B9B8CE',
              fontSize: 12,
            },
            inverse: false,
            axisLabel: {
              show: true,
              fontSize: 12,
              color: '#B9B8CE',
              rotate: 0,
            },
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#B9B8CE',
                width: 1,
              },
              onZero: true,
            },
            axisTick: {
              show: true,
              length: 5,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#484753',
                width: 1,
                type: 'solid',
              },
            },
          },
        ],
        legend: {
          show: true,
          top: '5%',
          textStyle: {
            color: '#B9B8CE',
          },
        },
        grid: {
          show: false,
          left: '10%',
          top: '60',
          right: '10%',
          bottom: '60',
        },
        dataset: null,
        renderer: 'svg',
      },
      previewScaleType: 'primitive',
    },
    componentList: [],
    requestGlobalConfig: {
      requestDataPond: [],
      requestOriginUrl: '',
      requestInterval: 30,
      requestIntervalUnit: 'second',
      requestParams: {
        Body: {
          'form-data': {},
          'x-www-form-urlencoded': {},
          json: '',
          xml: '',
        },
        Header: {},
        Params: {},
      },
    },
  }
}
