<template>
  <div class="pagination">
    <a-config-provider :locale="locale">
      <a-pagination
        :page-size-options="pageSizeOptions"
        :show-size-changer="true"
        :show-total="total => `总共 ${total} 条`"
        :total="total"
        @change="onChange"
        show-quick-jumper
        v-model:current="page"
        v-model:pageSize="perPage"
      />
    </a-config-provider>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: 'CommModal',
  components: {
    InfoCircleOutlined
  },
  props: {
    // 第几页
    page: {
      type: Number,
      default: 1
    },
    // 每页条数
    perPage: {
      type: Number,
      default: 10
    },
    // 总条数
    total: {
      type: Number,
      default: 0
    },
    // 页码改变的回调
    onChange: {
      type: Function
    },
    showQuickJumper: {
      type: Boolean,
      default: false
    },
    pageSizeOptions: {
      type: Array,
      default: ['10', '20', '30', '40', '50']
    }
  },

  setup(props, { emit }) {
    const state = reactive({
      locale: zhCN
    })

    const onChange = (pageNumber, pageSize) => {
      emit('onChange', pageNumber, pageSize)
      emit('update:page', pageNumber)
    }

    return {
      onChange,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped lang="less">
.pagination {
  padding: 0 16px;
  text-align: end;
}

/deep/ .ant-pagination-options {
  display: inline-block !important;
}
</style>
