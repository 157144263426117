import Layout from '@/layout/index.vue'
import Example from '@/views/example/index.vue'

export default {
  path: '/exampleLayout',
  name: 'example',
  component: Layout,
  redirect: '/example',
  meta: {
    title: 'Example',
    hidden:true
  },
  children: [
    {
      path: '/example',
      name: 'Example',
      component: Example,
      meta: {
        title: 'Example',
        hidden:true,
        icon: 'icon-data-source',
      },
    }
  ]
}
