import { AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig, AxiosInstance } from "axios";
import { Interceptors } from "./interceptors";
import { Iparam, IBodyData, IHttpClient } from '@/types';

// 请求配置
export class HttpServer implements IHttpClient {
  axios: AxiosInstance;
  // 获取axios实例
  constructor() {
    this.axios = new Interceptors().getInterceptors()
  }

  request<T>(config: AxiosRequestConfig): AxiosPromise<T> {
    return new Promise((resolve, reject) => {
      this.axios(config).then((res: AxiosResponse) => {
        resolve(res);
      }).catch((err: AxiosError) => {
        reject(err)
      });
    });
  }
  /**
   * 
   * @param url 请求的资源
   * @param param 问号参数
   * @param config axios的config参数
   */
  get<T>(url: string, param?: Iparam, config?: AxiosRequestConfig): AxiosPromise<T> {
    const _config = {
      url,
      param: param || {}
    }
    if (config) {
      Object.assign(_config, config)
    }
    return this.request(_config)
  }
  /**
   * 
   * @param url 请求的资源
   * @param data 数据包
   * @param param 问号参数
   * @param config axios的config参数
   */
  post<T>(url: string, data?: IBodyData, param?: Iparam, config?: AxiosRequestConfig): AxiosPromise<T> {
    const _config = {
      url,
      param: param || {},
      data: data || {}
    }
    if (config) {
      Object.assign(_config, config)
    }
    return this.request(_config)
  }
  /**
   * 
   * @param url 请求的资源
   * @param data 数据包
   * @param param 问号参数
   * @param config axios的config参数
   */
  put<T>(url: string, data?: IBodyData, param?: Iparam, config?: AxiosRequestConfig): AxiosPromise<T> {
    const _config = {
      url,
      param: param || {},
      data: data || {}
    }
    if (config) {
      Object.assign(_config, config)
    }
    return this.request(_config)
  }
}

const httpClient = new HttpServer()

export default httpClient