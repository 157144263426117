import OAuthConfig from './OAuthConfig'
import { message } from 'ant-design-vue'
import sysTemInfo from './systemInfo'
const {
  HSOAUTH_CLIENT_URI,
  HSOAUTH_REDIRECT_URI_IMLICT,
  HSOAUTH_CLIENT_ID_IMLICT,
  HSOAUTH_RESPONSE_TYPE_IMLICT,
  HSOAUTH_SCOPE_IMLICT,
  CONNECT_AUTHORIZE
} = OAuthConfig
function getURLParameters (url) {
  const url_ = url || location.href
  return (url_.match(/([^?=&]+)(=([^&]*))/g) || []).reduce((a, v) => {
    a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)
    return a
  }, {})
}
function getAuthInfo () {
  const params = getURLParameters()
  const { token, refresh_token } = getTokenRefreshToken()
  return {
    token: params.token || token,
    refresh_token: params.refresh_token || refresh_token
  }
}
function getRefreshTokenStorage () {
  return sessionStorage['refresh_token']
}
function setRefreshTokenStorage (refreshToken) {
  sessionStorage['refresh_token'] = refreshToken
}
function removeRefreshTokenStorage () {
  sessionStorage.removeItem['refresh_token']
}
function getTokenStorage () {
  return sessionStorage['token']
}
function setTokenStorage (token) {
  sessionStorage['token'] = token
}
function removeTokenStorage () {
  sessionStorage.removeItem['token']
}
function removeUserInfo () {
  sessionStorage.removeItem('user_info')
}
function getUserInfo (issignStr) {
  const data = sessionStorage['user_info']
  if (data) {
    return !issignStr ? JSON.parse(data) : data
  } else {
    return !issignStr ? {} : '{}'
  }
}
function setUserInfo (obj) {
  sessionStorage['user_info'] = JSON.stringify(obj)
}
function getTokenRefreshToken () {
  return {
    token: getTokenStorage(),
    refresh_token: getRefreshTokenStorage()
  }
}
function removeTokenRefreshToken () {
  removeRefreshTokenStorage()
  removeTokenStorage()
}
function setTokenRefreshToken (token, refresh_token) {
  setRefreshTokenStorage(refresh_token)
  setTokenStorage(token)
}
function getCommonInfo () {
  const isopenLogkey = getRecordLogKey()
  const { token, refresh_token } = getTokenRefreshToken()
  var data = {
    header: token,
    token,
    refresh_token,
    lang: localStorage['lang'],
    user_info: getUserInfo(true),
    tenant: sessionStorage['tenant'],
    app_list: sessionStorage['sourceAppUrl'],
    [isopenLogkey]: getIsRecordLog()
  }
  return data
}
function getAllIframe () {
  return Array.from(document.getElementsByTagName('iframe'))
}
// 获取记录日志的key
function getRecordLogKey () {
  const nowTime = new Date() // 获取年月日
  return `isopenLog-${nowTime.getFullYear()}-${nowTime.getMonth() +
    1}-${nowTime.getDate()}`
}
// 获取缓存中的是否记录日志的值
function getIsRecordLog () {
  return sessionStorage[getRecordLogKey()]
}

function JSONParse (data) {
  if (typeof data === 'string' && data) {
    return JSON.parse(data)
  } else {
    return data
  }
}
// 把&链接的字符串转化成对象形式"a=1&b=2&c=3" 或者 "#a=1&b=2&c=3" 或者 "?a=1&b=2&c=3"或者"/a=1&b=2&c=3"
function transferUrlQueryStrToObj (str) {
  let _str = str
  if (str.startsWith('#') || str.startsWith('?') || str.startsWith('/')) {
    _str = str.slice(1)
  } else if (
    str.startsWith('#&') ||
    str.startsWith('?&') ||
    str.startsWith('/&')
  ) {
    _str = str.slice(2)
  }
  return _str.split('&').reduce((totle, pre) => {
    const [prop, value] = pre.split('=')
    totle[prop] = value
    return totle
  }, {})
}
// 将对象转化成 &链接的字符串
function transObjToUrlQuery (data) {
  return Object.keys(data).reduce((totle, current) => {
    totle += `&${current}=${data[current]}`
    return totle
  }, '')
}
function initImlictUrl () {
  return
}
// 简化模式登录
// 简化模式
function handleLoginImlict (tenant_code) {
  sessionStorage['tenant_code'] = tenant_code || ''
  // 首先使用传入的租户code,如果没有就去取url上的,然后在没有就去取session中的.
  // 获取url上的来源source
  const params = getURLParameters() // 获取url信息
  const _tenant = tenant_code || '' // 租户
  const source = params.source || sessionStorage['source'] || 'saas' // 来源
  // 读取配置中的参数
  // 应用client_id
  let _HSOAUTH_CLIENT_ID_IMLICT = HSOAUTH_CLIENT_ID_IMLICT
  if (window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_ID_IMLICT) {
    _HSOAUTH_CLIENT_ID_IMLICT = window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_ID_IMLICT
  }
  // 请求的后端地址
  let _HSOAUTH_CLIENT_URI = HSOAUTH_CLIENT_URI
  if (window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_URI) {
    _HSOAUTH_CLIENT_URI = window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_URI
  }
  // scope
  let _HSOAUTH_SCOPE_IMLICT = HSOAUTH_SCOPE_IMLICT
  if (window.PROXY_URL_CONFIG.HSOAUTH_SCOPE_IMLICT) {
    _HSOAUTH_SCOPE_IMLICT = window.PROXY_URL_CONFIG.HSOAUTH_SCOPE_IMLICT
  }
  let _HSOAUTH_REDIRECT_URI_IMLICT = HSOAUTH_REDIRECT_URI_IMLICT
  if (window.PROXY_URL_CONFIG.HSOAUTH_REDIRECT_URI_IMLICT) {
    _HSOAUTH_REDIRECT_URI_IMLICT =
      window.PROXY_URL_CONFIG.HSOAUTH_REDIRECT_URI_IMLICT
  }
  const obj = {
    client_id: _HSOAUTH_CLIENT_ID_IMLICT,
    redirect_uri: _HSOAUTH_REDIRECT_URI_IMLICT,
    response_type: HSOAUTH_RESPONSE_TYPE_IMLICT,
    scope: _HSOAUTH_SCOPE_IMLICT,
    nonce: new Date().valueOf(),
    tenant: _tenant,
    source: source
  }
  const query = transObjToUrlQuery(obj)
  const HSOAUTH_CLIENT_CONNECT_AUTHORIZE =
    _HSOAUTH_CLIENT_URI + CONNECT_AUTHORIZE
  var path = HSOAUTH_CLIENT_CONNECT_AUTHORIZE + '?' + query
  if (location.hostname !== 'localhost') {
    // window.open(`/hs_auth_imlict?tenant=${_tenant}&source=${source}`, '_self')
    console.log(path)
    window.open(path, '_self')
  } else {
    window.open(
      `http://47.110.145.204:8256/connect/authorize?&client_id=implict_test&redirect_uri=http://localhost:50001/signin-oidc&response_type=token&scope=UserRight&nonce=1627262479247&tenant=${_tenant}&source=${source}`,
      '_self'
    )
  }
}
// code模式
function handleLoginHsCode (tenant_code) {
  const params = getURLParameters()
  const source = params.source || ''
  window.open(`/hs_auth_code?tenant=${tenant_code}&source=${source}`, '_self')
  // window.open(
  //   `http://47.110.145.204:8256/connect/authorize?client_id=implict_test&redirect_uri=http%3A%2F%2Flocalhost%3A8000%2Fsignin-oidc&response_type=id_token%20token&scope=openid%20profile%20role%20HSTool&response_mode=fragment&state=54321&x-client-SKU=ID_NETSTANDARD2_0&x-client-ver=5.5.0.0&nonce=12345&tenant=${tenant_code}`,
  //   '_self'
  // )
}
function getMenuList () {
  if (sessionStorage['menu']) {
    return JSON.parse(sessionStorage['menu'])
  } else {
    return []
  }
}
// code模式登录
// post 方式打开页面
function postOpenWindow (URL, PARAMS) {
  var temp_form = document.createElement('form')
  temp_form.action = URL
  temp_form.target = '_blank'
  temp_form.method = 'post'
  temp_form.style.display = 'none'
  for (var x in PARAMS) {
    var opt = document.createElement('textarea')
    opt.name = x
    opt.value = PARAMS[x]
    temp_form.appendChild(opt)
  }
  document.body.appendChild(temp_form)
  temp_form.submit()
}
async function hsConfirm (title) {
  return ElMessageBox.confirm(title, '提示', {
    confirmButtonText: '确定',
    showCancelButton: false,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    showClose: false,
    type: 'warning',
    center: true
  })
}
function oidcLogin (oidc) {
  ; (window.oidc || oidc).getSignedIn().then(
    () => {
      console.log('重新打开登录页 success')
    },
    err => {
      console.log(err)
    }
  )
}
/**
 * @description 移除sessionStorage
 * @param {String} key
 */
const deleteSessionItem = key => {
  const target = sessionStorage.getItem(key)
  target && sessionStorage.removeItem(key)
}
// 移除登录信息
const removeInfo = () => {
  deleteSessionItem('token')
  deleteSessionItem('user_info')
  deleteSessionItem('id_token')
}
/**
 * 获取环境变量中是否配置了打开oidc
 * @returns 
 */
function getIsOpenOidc () {
  const config = window.PROXY_URL_CONFIG || {}
  return config.openOidc === '1' || config.openOidc === 1 || config.openOidc === undefined
}
/**
 * 获取环境变量中是否配置了是否需要检查用户app权限
 * @returns 
 */
function getUserRightAppCheckout () {
  const config = window.PROXY_URL_CONFIG || {}
  return config.userRightAppCheckout === '1' || config.userRightAppCheckout === 1
}
export default {
  getURLParameters,
  getAuthInfo,
  removeUserInfo,
  getUserInfo,
  setUserInfo,
  getRefreshTokenStorage,
  setRefreshTokenStorage,
  removeRefreshTokenStorage,
  removeTokenStorage,
  getTokenStorage,
  setTokenStorage,
  getTokenRefreshToken,
  removeTokenRefreshToken,
  setTokenRefreshToken,
  getCommonInfo,
  getAllIframe,
  getRecordLogKey,
  getIsRecordLog,
  transferUrlQueryStrToObj,
  handleLoginImlict,
  handleLoginHsCode,
  getMenuList,
  hsConfirm,
  oidcLogin,
  $sysTemInfo: sysTemInfo,
  transObjToUrlQuery,
  removeInfo,
  getIsOpenOidc,
  getUserRightAppCheckout
}
