import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import navigationDisplay from './modulesCatalogue/navigation'
import sourceManage from './modulesCatalogue/sourceManage'
// import reportDesigner from './modulesCatalogue/reportDesigner'
import apiManage from './modulesCatalogue/apiManage'
import Example from './modulesCatalogue/example'
import CarouselManage from './modulesCatalogue/carouselManage'
import { signIn } from '@/utils/oidc'
import toolFunction from '@/utils/toolFunction.js'
import systemInfo from '@/utils/systemInfo.js'
type CustomRouteRaw = RouteRecordRaw & {
  hidden?: boolean
}
const routes: Array<CustomRouteRaw> = [
  navigationDisplay,
  // reportDesigner,
  sourceManage,
  apiManage,
  CarouselManage,
  Example,
  {
    path: '/',
    name: 'home',
    redirect: '/layout-bi',
    meta: {
      hidden: true,
    },
  },
  {
    path: '/needUserAppRight',
    name: 'needUserAppRight',
    component: () => import('@/views/tipsPage/needUserAppRight.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/editor',
    name: 'editor',
    meta: {
      hidden: true,
    },
    component: () => import('@/pages/page-editor'),
    beforeEnter: (to, from, next) => {
      console.log('editor---', to, from)
      if (from.path !== '/editor') {
        const { href } = router.resolve({
          path: '/editor',
        })
        // window.open(href, '_blank')
        next()
      } else {
        next()
      }
    },
  },
  {
    path: '/preview',
    name: 'preview',
    meta: {
      hidden: true,
    },
    component: () => import('@/preview'),
  },
  {
    path: '/view',
    name: 'View',
    meta: { hidden: true },
    component: () => import('@/views/publishView')
  },
  {
    path: '/registerApp',
    name: 'registerApp',
    meta: { hidden: true },
    component: () => import('@/views/registerApp/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { hidden: true },
    component: () => import('@/views/login/index.vue')
  },
  {
    // 跳转到这个界面,去自动登录
    path: '/re-login',
    name: 're-login',
    meta: {
      hidden: true,
    },
    component: () => import(/* webpackChunkName: "app-shop" */ '@/views/oAouth/re-login.vue'),
  },
  {
    path: '/signin-oidc',
    name: 'signin-oidc',
    meta: {
      hidden: true,
    },
    component: () => import(/* webpackChunkName: "app-shop" */ '@/views/oAouth/signin-oidc.vue'),
  },
  {
    path: '/signout-oidc',
    meta: {
      hidden: true,
    },
    name: 'signout-oidc',
    component: () => import(/* webpackChunkName: "app-shop" */ '@/views/oAouth/signout-oidc.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
const pathList = ['/needUserAppRight', '/signin-oidc', '/signout-oidc', '/login', '/view']
// 地址栏租户切换
const initTenant = (route) => {
  const tenantCode = route.query?.tenant as string
  const tenantPlat = route.query?.tenantPlat as string
  const defaultTenant = systemInfo.getDefaultTenant()
  const tenantSession = systemInfo.getTenant()
  if (!tenantSession && defaultTenant) {// 1.缓存中不存在租户信息则把默认租户设置到缓存中
    sessionStorage.setItem('tenant_code', defaultTenant)
  }
  if (tenantCode) {// 2.url 上的租户code存在则设置到缓存中
    sessionStorage.setItem('tenant_code', tenantCode)
  }
  tenantPlat && sessionStorage.setItem('tenantPlat', tenantPlat)
  const token = toolFunction.getTokenStorage()
  const isOpenOidc = toolFunction.getIsOpenOidc()
  // 1.缓存中无token则需要登陆
  // 2.url上租户存在并且与缓存中的不一致
  // 看配置中是否开启了oidc,开启了则继续检查是否存在token，存在则继续走下去，不存在则跳转oidc
  if (isOpenOidc && !token && route.path !== '/registerApp' && !pathList.includes(route.path)) {// 
    //signIn()
  }
}


router.beforeEach(async (to, from, next) => {


  initTenant(to)
  // 如果开启了 验证userAppRight 并且 开启了odic
  // 本地版本不调用app权限检查。
  if (toolFunction.getIsOpenOidc() && toolFunction.getUserRightAppCheckout() && toolFunction.getTokenStorage()) {
    if (sessionStorage['hasUserAppRight'] !== '1' && !pathList.includes(to.path)) {
      next('/needUserAppRight')
    }
  }
  const tenant = systemInfo.getTenant()
  // const tenantPlat = systemInfo.getTenantPlat()
  // url上的租户code不存在,且本地缓存中存在租户code，并且不在pathList里的路由
  if ((!to.query.tenant) && tenant && !pathList.includes(to.path) && to.path !== '/preview' && to.path !== '/editor') {
    next({
      path: to.path,
      query: {
        ...(to.query || {}),
        tenant
      }
    })
  } else {
    next()
  }
})

export default router
