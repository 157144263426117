import * as echarts from 'echarts/core'
import { BarChart, LineChart, PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { onBeforeUnmount, ref, Ref, markRaw, nextTick } from 'vue'
import type { EChartsOption } from 'echarts'

type ChartConfig = {
  el: Ref<HTMLElement>
  options: EChartsOption
}

// onResize
class ChartsResize {
  static #timer = null

  static handleResize(chartsInstance: echarts.ECharts) {
    clearTimeout(ChartsResize.#timer)
    ChartsResize.#timer = setTimeout(() => {
      chartsInstance?.resize()
    }, 500)
  }

  // static setupResize(chartsInstance: echarts.ECharts) {
  //   window.addEventListener(
  //     'resize',
  //     () => ChartsResize.handleResize(chartsInstance),
  //     false
  //   )
  // }

  // static removeListener(chartsInstance: echarts.ECharts) {
  //   window.removeEventListener(
  //     'resize',
  //     () => ChartsResize.handleResize(chartsInstance),
  //     false
  //   )
  // }
}

// hooks
export const useCharts = ({ el, options }: ChartConfig) => {
  // console.log('useCharts',el,options)
  echarts.use([BarChart, LineChart, PieChart, CanvasRenderer])

  const chartsInstance = ref<echarts.ECharts>()

  // 更新charts
  const setOptions = (options: EChartsOption) => {
    chartsInstance.value.setOption(options, true)
  }

  // 初始化charts
  const initCharts = () => {
    // TODO: fixed 将一个由reactive生成的响应式对象转为普通对象
    // 标记一个对象，使其永远不会再成为响应式对象
    nextTick(() => {
      if (!el.value) {
        return
      }
      echarts.dispose(el.value)
      if (!chartsInstance.value) {
        chartsInstance.value = markRaw(echarts.init(el.value))
      }
      // console.log('chartsInstance.value--', chartsInstance.value);
      setOptions(options)
      // ChartsResize.setupResize(chartsInstance.value)
    })
    
  }

  // 组件卸载时、移除resize监听事件
  // onBeforeUnmount(() => {
  //   ChartsResize.removeListener(chartsInstance.value)
  // })

  return {
    chartsInstance,
    initCharts,
    setOptions,
  }
}
