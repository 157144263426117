<template>
  <a-modal
    width="60%"
    title="值列表"
    @cancel="handleCancel"
    @ok="handleConfirm"
    destroyOnClose
    v-model:visible="visible"
    cancelText="取消"
    :afterClose="afterClose"
    :maskClosable="false"
    class="create-page-modal"
  >
    <a-textarea v-model:value="value" placeholder="请输入值列表" :rows="5"/>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  name: 'ValueListDialog',
  props: {
    visible: { type: Boolean, default: false },
    value: { type: String, default: '' },
  },
  emits: ['update:visible', 'update:value'],
  setup(props, { emit }) {
    const state = reactive({})
    const handleCancel = () => {
      emit('update:visible', false)
    }
    const handleConfirm = () => {
      emit('update:value', props.value)
      emit('update:visible', false)
    }
    const afterClose = () => {
      emit('update:visible', false)
    }
    return {
      ...toRefs(state),
      handleCancel,
      handleConfirm,
      afterClose,
    }
  }
}
</script>

<style scoped>

</style>
