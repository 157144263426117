import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'

import { message } from 'ant-design-vue'
import { removeInfo } from '@/utils'
import toolFunction from '@/utils/toolFunction'
import { signIn } from '@/utils/oidc'

//后端数据格式
export interface IResponseResult<T = any> extends AxiosResponse {
  code: string | number
  message: string
  success: boolean
  data: T
  status: number
  [key: string]: any
}

const getToken = () => sessionStorage.getItem('token') as string

class Request {
  // axios 实例
  private instance: AxiosInstance
  // 默认基础配置
  private baseConfig: AxiosRequestConfig = {
    timeout: 60000,
    // withCredentials: true,
  }

  constructor(config: AxiosRequestConfig = {}) {
    this.baseConfig = Object.assign(this.baseConfig, config)
    this.instance = axios.create(this.baseConfig)
    this.requestInterceptor(this.instance)
    this.responseInterceptor(this.instance)
  }

  static replaceStartProxy(url: string): string {
    const isDevelopment = process.env.NODE_ENV === 'development'
    let _url = url
    const index = _url.indexOf('/')
    const PROXY_CONFIG_STR = sessionStorage['PROXY_URL_CONFIG']
    const PROXY_CONFIG = PROXY_CONFIG_STR ? JSON.parse(PROXY_CONFIG_STR) : {}
    if (index !== -1 && PROXY_CONFIG_STR && !_url.startsWith('http')) {
      // 说明存在斜杠
      const start = _url.slice(0, index)
      // if (isDevelopment) {
      //   return `${location.origin}/${start}${_url.slice(index)}`
      // }
      if (PROXY_CONFIG && PROXY_CONFIG[start]) {
        _url = PROXY_CONFIG[start] + _url.slice(index)
      }
    }
    return _url
  }

  // 请求拦截器
  private requestInterceptor(instance) {
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (getToken()) {
          config.headers['Authorization'] = getToken()
        }

        config.url = Request.replaceStartProxy(config.url)
        return config
      },
      (error: AxiosError) => {
        return Promise.reject(error)
      }
    )
  }

  // 返回拦截器
  private responseInterceptor(instance) {
    instance.interceptors.response.use(
      (response: IResponseResult) => {
        const {
          config: { responseType },
          data: { success, message: information, code },
        } = response
        // blob类型直接返回
        if (responseType === 'blob') {
          return Promise.resolve(response.data)
        }
        if (!success) {
          message.error(information || code || '未知错误！')
          throw new Error(response.data.message || response.data.code);
        }
        return Promise.resolve(response.data as IResponseResult)
      },
      (error: AxiosError) => {
        const { response } = error
        if (response.status === 401) {
          removeInfo()
          toolFunction.$sysTemInfo.setSigninCallbackUrl(location.href)
          signIn()
          return
        }
        message.error(response?.data?.message ||error|| '未知错误！')
        return Promise.reject(response?.data)
      }
    )
  }

  public request(config: AxiosRequestConfig): Promise<IResponseResult> {
    return this.instance.request(config)
  }
}

export default new Request()
