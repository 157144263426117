/* eslint-disable */
import Oidc from 'oidc-client'
import 'babel-polyfill'
import OAuthConfig from './OAuthConfig'
import systemInfo from './systemInfo'
import { DEFAULT_TENANT_PLAT } from './constance'
const {
  HSOAUTH_RESPONSE_TYPE_IMLICT,
} = OAuthConfig
// 请求的后端地址
let _HSOAUTH_CLIENT_URI
// 应用client_id
let _HSOAUTH_CLIENT_ID_IMLICT

let _HSOAUTH_REDIRECT_URI_IMLICT

// scope
let _HSOAUTH_SCOPE_IMLICT

let _HSOAUTH_POST_LOGOUT_REDIRECT_URI_IMLICT
// 设置环境变量
let mgr = ''
function init () {
  if (window.PROXY_URL_CONFIG) {
    if (window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_URI) {
      _HSOAUTH_CLIENT_URI = window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_URI
    }
    if (window.PROXY_URL_CONFIG.HSOAUTH_REDIRECT_URI_IMLICT) {
      _HSOAUTH_REDIRECT_URI_IMLICT =
        window.PROXY_URL_CONFIG.HSOAUTH_REDIRECT_URI_IMLICT
    }
    if (window.PROXY_URL_CONFIG.HSOAUTH_SCOPE_IMLICT) {
      _HSOAUTH_SCOPE_IMLICT = window.PROXY_URL_CONFIG.HSOAUTH_SCOPE_IMLICT
    }
    if (window.PROXY_URL_CONFIG.HSOAUTH_POST_LOGOUT_REDIRECT_URI_IMLICT) {
      _HSOAUTH_POST_LOGOUT_REDIRECT_URI_IMLICT =
        window.PROXY_URL_CONFIG.HSOAUTH_POST_LOGOUT_REDIRECT_URI_IMLICT
    }
    if (window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_ID_IMLICT) {
      _HSOAUTH_CLIENT_ID_IMLICT =
        window.PROXY_URL_CONFIG.HSOAUTH_CLIENT_ID_IMLICT
    }
  }
  if (mgr) return
  mgr = new Oidc.UserManager({
    userStore: new Oidc.WebStorageStateStore(),
    authority: _HSOAUTH_CLIENT_URI,
    client_id: _HSOAUTH_CLIENT_ID_IMLICT,
    redirect_uri: _HSOAUTH_REDIRECT_URI_IMLICT,
    response_type: HSOAUTH_RESPONSE_TYPE_IMLICT,
    scope: _HSOAUTH_SCOPE_IMLICT,
    post_logout_redirect_uri: _HSOAUTH_POST_LOGOUT_REDIRECT_URI_IMLICT,
    silent_redirect_uri: '',
    accessTokenExpiringNotificationTime: 10,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    extraQueryParams: {
      nonce: new Date().valueOf()
    },
    loadUserInfo: true
  })
  mgr.events.addUserLoaded(function (user) {
    console.log('New User Loaded：', arguments)
    console.log('Acess_token: ', user.access_token)
  })
  mgr.events.addAccessTokenExpiring(function () {
    console.log('AccessToken Expiring：', arguments)
  })

  mgr.events.addAccessTokenExpired(function () {
    console.log('AccessToken Expired：', arguments)
    alert('Session expired. Going out!')
    mgr
      .signoutRedirect()
      .then(function (resp) {
        console.log('signed out', resp)
      })
      .catch(function (err) {
        console.log(err)
      })
  })

  mgr.events.addSilentRenewError(function () {
    console.error('Silent Renew Error：', arguments)
  })

  mgr.events.addUserSignedOut(function () {
    alert('Going out!')
    console.log('UserSignedOut：', arguments)
    mgr
      .signoutRedirect()
      .then(function (resp) {
        console.log('signed out', resp)
      })
      .catch(function (err) {
        console.log(err)
      })
  })
}
init()

Oidc.Log.logger = console
Oidc.Log.level = Oidc.Log.INFO
export default class SecurityService {
  constructor() { }
  // Renew the token manually
  renewToken () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .signinSilent()
        .then(function (user) {
          if (user == null) {
            self.signIn(null)
          } else {
            return resolve(user)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Get the user who is logged in
  getUser () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(null)
          } else {
            return resolve(user)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Check if there is any user logged in
  getSignedIn () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(false)
          } else {
            return resolve(true)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Redirect of the current window to the authorization endpoint.可传入参数
  signIn () {
    const tenant = systemInfo.getTenant()
    if (!tenant) {
      console.error('tenant必传')
      //return
    }
    const parms = {
      tenant: tenant || '',
      tenantPlat: 'saas',// systemInfo.getTenantPlat() || DEFAULT_TENANT_PLAT,
      nonce: new Date().valueOf()
    }
    mgr._settings._extraQueryParams = parms
    console.log('login parms', parms)
    mgr.signinRedirect(parms).catch(function (err) {
      console.log(err)
    })
  }

  // Redirect of the current window to the end session endpoint,可以传入额外参数
  signOut () {
    const parms1 = {
      id_token_hint: systemInfo.getIdToken()
    }
    const parms2 = {
      tenant: systemInfo.getTenant() || '',
      tenantPlat: 'saas',// systemInfo.getTenantPlat() || DEFAULT_TENANT_PLAT,
      nonce: new Date().valueOf()
    }
    const that = this
    mgr._settings._extraQueryParams = parms2
    mgr
      .signoutRedirect(parms1)
      .then(function (resp) {
        console.log('signed out', resp)
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  // Get the profile of the user logged in
  getProfile () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(null)
          } else {
            return resolve(user.profile)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Get the token id
  getIdToken () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(null)
          } else {
            return resolve(user.id_token)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Get the session state
  getSessionState () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(null)
          } else {
            return resolve(user.session_state)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Get the access token of the logged in user
  getAcessToken () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(null)
          } else {
            return resolve(user.access_token)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Takes the scopes of the logged in user
  getScopes () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(null)
          } else {
            return resolve(user.scopes)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }

  // Get the user roles logged in
  getRole () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn()
            return resolve(null)
          } else {
            return resolve(user.profile.role)
          }
        })
        .catch(function (err) {
          console.log(err)
          return reject(err)
        })
    })
  }
  getLoginParams () {
    return {
      client_id: _HSOAUTH_CLIENT_ID_IMLICT,
      redirect_uri: _HSOAUTH_REDIRECT_URI_IMLICT,
      response_type: HSOAUTH_RESPONSE_TYPE_IMLICT,
      scope: _HSOAUTH_SCOPE_IMLICT,
      nonce: new Date().valueOf(),
      tenant: systemInfo.getTenant() || '',
      tenantPlat: 'saas'//DEFAULT_TENANT_PLAT
    }
  }
}
