import service from '@/utils/request'
import { API_PROXY, USERRIGHTAPI } from './commonProxy'

/**
 * @description 数据源管理
 */

// 获取API列表
export const getApiManageList = params => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi`,
    method: 'get',
    params,
  })
}

// 复制
export const copyApi = (id: string) => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi/copy/${id}`,
    method: 'get',
  })
}

// 获取api详情
export const getApiDetail = id => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi/${id}`,
    method: 'get',
  })
}

// 更新api/db 详情数据
export const updateParamsInfo = (id, data) => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi/${id}`,
    method: 'put',
    data,
  })
}

// 发布任务
export const releaseTask = data => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi/release`,
    method: 'post',
    data,
  })
}

// api新增任务
export const saveTaskService = data => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi`,
    method: 'post',
    data,
  })
}

// 获取数据源
export const getDataSource = () => {
  return service.request({
    url: `${API_PROXY}/api/dataResource/list`,
    method: 'get',
  })
}

// 解析SQL
export const analysisSQL = data => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi/sqlRunParsing`,
    method: 'post',
    data,
  })
}

// DB 获取返回参数
export const getReturnParams = data => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi/sqlRunParsing`,
    method: 'post',
    data,
  })
}

// api 删除接口
export const deleteParamsInfo = data => {
  return service.request({
    url: `${API_PROXY}/api/dyncApi`,
    method: 'delete',
    data,
  })
}

export const pageBindApis = (id, data) => {
  return service.request({
    url: `${API_PROXY}/api/webPage/page_dync_api/${id}`,
    method: 'put',
    data,
  })
}

export const exportPage = (data: { ids: string[] }) => {
  return service.request({
    url: `${API_PROXY}/api/webPage/export`,
    method: 'post',
    data,
    responseType: 'blob'
  })
}

export const checkUserAppRight = () => {
  return service.request({
    url: `${USERRIGHTAPI}/api/user/app/verify/DyncBi`,
    method: 'get',
  })
}