<template>
  <div class="schema-content">
    <template v-if="data.type ==='array'">
      <SchemaArray
        v-bind="$attrs"
        :prefix="name"
        :data="data"
        :editor-id="editorId"
      />
    </template>
    <template v-if="data.type ==='object'">
      <SchemaObject
        ref="schemaObject"
        v-bind="$attrs"
        :prefix="nameArray"
        :data="data"
        :editor-id="editorId"
      />
    </template>
  </div>
</template>

<script>
import SchemaObject from './SchemaObject'
import SchemaArray from './SchemaArray'
import { computed, reactive, ref, toRefs } from 'vue'

export default {
  name: 'index',
  components: {
    SchemaObject,
    SchemaArray
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    editorId: {
      type: String,
      default: ''
    },
  },
  setup() {
    const schemaObject = ref()
    const state = reactive({
      name: []
    })
    const nameArray = computed(() => [...state.name, 'properties'])
    const handleAdd = (newName) => {
      schemaObject.value?.handleAdd({ newName })
    }

    return {
      schemaObject,
      ...toRefs(state),
      nameArray,
      handleAdd
    }
  }
}
</script>

<style scoped>

</style>
