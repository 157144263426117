import HsOidcClient from './hs-oidc-client'
import { DEFAULT_TENANT_PLAT } from '@/utils/constance'
import toolFunction from '@/utils/toolFunction.js'
import systemInfo from './systemInfo'
import router from '@/router/index'

const isOpenOidc = toolFunction.getIsOpenOidc()

const hsOidcClient = new HsOidcClient()

/**
 *
 * @param {String} tenant 租户code
 * @param {String} tenantPlat 租户平台 paas
 */
export function getSignedIn ({ tenant = '', tenantPlat = DEFAULT_TENANT_PLAT } = {}) {
	hsOidcClient.getSignedIn(tenant, tenantPlat)
}

/**
 *
 * @param {String} tenant 租户code
 * @param {String} tenantPlat 租户平台 paas
 */
export function signIn ({ tenant = '', tenantPlat = DEFAULT_TENANT_PLAT } = {}) {
	// 判断一下 是否是本地还线上 ，本地的话就跳转到登陆界面去
	hsOidcClient.signIn(tenant, tenantPlat)
	// if (isOpenOidc) {
	// 	hsOidcClient.signIn(tenant, tenantPlat)
	// } else {
	// 	const tenant_ = systemInfo.getTenant()
	// 	router.push({
	// 		path: '/login',
	// 		query: {
	// 			tenant: tenant || tenant_ || ''
	// 		}
	// 	})
	// }

}

/**
 *
 * @param {String} tenant  租户code
 * @param {String} tenantPlat 租户平台
 * @param {String} idTokenHint token
 */
export function signOut ({
	tenant = '',
	tenantPlat = DEFAULT_TENANT_PLAT,
	idTokenHint: id_token_hint = ''
} = {}) {
	hsOidcClient.signOut(tenant, tenantPlat, id_token_hint)
}
