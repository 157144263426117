import navigationDisplay from '@/views/homeCatalogue/navigation-display/index.vue'
import Layout from '@/layout/index.vue'

export default {
  path: '/layout-bi',
  name: 'navigationDisplay',
  component: Layout,
  redirect: '/navigation-display',
  meta: {
    title: '大屏设计器',
    hidden: true,
  },
  children: [
    {
      path: '/navigation-display',
      name: 'navigation-display',
      component: navigationDisplay,
      meta: {
        title: '大屏设计器',
        icon: 'icon-page-views',
        keepAlive: true,
      },
    },
  ],
}
