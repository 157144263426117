// 默认的租户平台
export const DEFAULT_TENANT_PLAT = 'saas'

// 默认背景图
export const DEFAULT_BACKGROUND = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAALSURBVHgBY2BABwAAEgABQ6T5WQAAAABJRU5ErkJggg==`

// 默认背景颜色
export const DEFAULT_BACKGROUND_COLOR = 'rgba(0,0,0,0)'

// 位置列表
export const positionList: any = [
  {
    label: '上',
    value: 'top',
  },
  {
    label: '左',
    value: 'left',
  },
  {
    label: '下',
    value: 'bottom',
  },
  {
    label: '右',
    value: 'right',
  },
  {
    label: '中',
    value: '',
  }
]
// 字体列表
export const fontFamilyList: any = [
  {
    label: 'Tahoma',
    value: 'Tahoma',
  },
  {
    label: 'Verdana',
    value: 'Verdana',
  },
  {
    label: 'Arial',
    value: 'Arial',
  },
  {
    label: 'Helvetica',
    value: 'Helvetica',
  },
  {
    label: '苹方',
    value: 'system-ui',
  },
  {
    label: '微软雅黑',
    value: 'system-ui',
  },
]

//时间格式设置 显示内容
export const contentOptions = [
  { label: '日期', value: 'date' },
  { label: '周', value: 'week' },
  { label: '时间', value: 'time' },
]
//时间格式设置 间隔符
export const intervalOptions = [
  { label: '-', value: '0' },
  { label: '/', value: '1' },
  { label: '中', value: '2' },
]

export const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

export const responseDataType = [
  {
    label: '不转换',
    value: '0',
  },
  {
    label: '二维数组',
    value: '1',
  },
  {
    label: '对象数组',
    value: '2',
  },
]

// 背景大小
export const backgroundSizeList = [
  {
    label: 'auto',
    value: 'auto'
  },
  {
    label: 'cover',
    value: 'cover'
  },
  {
    label: 'contain',
    value: 'contain'
  }
]

export const backgroundRepeatList = [
  {
    label: '重复',
    value: 'repeat'
  },
  {
    label: '不重复',
    value: 'no-repeat'
  },
]

export const typeDefaultValue = {
  'string': '',
  'number': 0,
  'integer': 0,
  'boolean': false,
}


export const HSOAUTH_RESPONSE_TYPE_IMLICT = 'id_token token'