export const JSONPATH_JOIN_CHAR = '.'
export const lang = 'zh_CN'
export const LOOK_SOURCE_CODE = '0'
export const IMPORT_SOURCE_CODE = '1'
export const EDITOR_REQUEST = '0'
export const EDITOR_RESPONSE = '1'

export const SCHEMA_TYPE = [
  'string',
  'number',
  'array',
  'object',
  'boolean',
  'integer'
]

export const SCHEMA_FORMAT = ['date', 'email', 'domainname', 'ip', '手机号']

export const defaultInitSchemaData = {
  type: 'object',
  title: 'title',
  properties: {}
}

export const defaultSchema = {
  string: {
    type: 'string'
    // format: '',
    // valueList: '',
    // minLength: undefined,
    // maxLength: undefined,
    // noNull:false
  },
  number: {
    type: 'number'
    // format: '',
    // valueList: '',
    // min: undefined,
    // max: undefined,
    // noNull:false
  },
  array: {
    type: 'array',
    items: {
      type: 'string'
    }
  },
  object: {
    type: 'object',
    // noNull:false,
    properties: {}
  },
  boolean: {
    type: 'boolean'
    // noNull:false
  },
  integer: {
    type: 'integer'
    // format: '',
    // valueList: '',
    // min: undefined,
    // max: undefined,
    // noNull:false
  }
}
