export const usableOptions = [
  {
    value: true,
    label: '启用',
  },
  {
    value: false,
    label: '未启用',
  },
]

/**
 * 页面columns
 */
export const pageColumns = Object.freeze([
  {
    title: '页面名称',
    dataIndex: 'pageName',
    ellipsis: true,
    width: 200
  },
  {
    title: 'PageCode',
    dataIndex: 'pageEname',
    ellipsis: true,
    width: 200
  },
  {
    title: 'appCode',
    dataIndex: 'appCode',
    ellipsis: true,
    width: 200
  },
  {
    title: '发布链接',
    dataIndex: 'publishUrl',
    width: 200,
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 90
  },
  {
    title: '是否启用',
    dataIndex: 'usable',
    width: 90
  },
  {
    title: '发布时间',
    dataIndex: 'publishTime',
    width: 200,
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 380,
    fixed: 'right'
  },
])

/**
 * 发布历史记录
 */
export const publishHistoryColumns = Object.freeze([
  {
    title: '版本',
    dataIndex: 'version',
  },
  {
    title: '备注',
    dataIndex: 'remark',
  },
  {
    title: '发布时间',
    dataIndex: 'publishTime',
    width: '180px',
  },
  {
    title: '操作',
    dataIndex: 'actions',
    width: '280px',
  },
])
