import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layout/index.vue')
const CarouselManage = () => import('@/views/homeCatalogue/carousel-manage/index.vue')
const BiDetail = () => import('@/views/homeCatalogue/carousel-manage/bi-detail/index.vue')

const CarouselManageRoutes: RouteRecordRaw = {
  path: '/carousel-manage',
  name: 'CarouselManageLayout',
  component: Layout,
  redirect: '/carousel-manage',
  meta: {
    title: '轮播管理',
    hidden: true
  },
  children: [
    {
      path: '/carousel-manage',
      name: 'CarouselManage',
      component: CarouselManage,
      meta: {
        title: '轮播管理',
        icon: 'icon-api-manage'
      }
    },
    {
      path: '/bi-detail',
      name: 'BiDetail',
      component: BiDetail,
      meta: {
        title: '轮播管理详情',
        hidden: true
      }
    }
  ]
}

export default CarouselManageRoutes