import { setLocalItem } from '@/utils/toolLocalStorage'

export interface IState {
  test: string,
}

const state: IState = {
  test: '111',
}

const mutations = {
  SET_DEFAULT_VALUE: (state: IState, value: string) => {
    console.log(state.test === value)
    state.test = value
  }
}

const actions = {
  saveTestData({ commit }, value) {
    // const timeStamp = new Date().getTime()
    // const key: string = `teat_${timeStamp}`
    const key: string = `test`
    commit('SET_DEFAULT_VALUE', value)
    // setLocalItem(key, value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
