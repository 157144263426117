// 首先引入axios和上一步封装的cookie方法
import axios, { AxiosInstance } from "axios";
import { message } from 'ant-design-vue'
import toolFunction from '@/utils/toolFunction.js'
import { signIn } from '@/utils/oidc'

/**
 * 删除url的指定参数
 * @param {*} url 需要处理的url
 * @param {*} name 参数名称
 */
const deleteQueryString = (url, name) => {
  try {
    const re = new RegExp('&?' + name + '=[^&]*', 'g')
    return url.replace(re, '')
  } catch (error) {
    console.log('deleteQueryString error', error)
  }
}

const TOKEN_KEY = 'token'

let flge401 = 0
async function oidcLogin() {
  // const reconfirm = await toolFunction.hsConfirm(
  //   '身份信息已过期!点击确定重新登录!'
  // )
  const reconfirm = true
  if (reconfirm) {
    flge401 = 0
    // TODO: singleUrl上拼接token以后、过期的token会一直复写session中的正确token、因此需要删除singleUrl上的token
    const singleUrl = location.href.includes(TOKEN_KEY) ? deleteQueryString(location.href, TOKEN_KEY) : location.href
    toolFunction.$sysTemInfo.setSigninCallbackUrl(singleUrl)
    signIn()
  }
}
export class Interceptors {
  instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "",
      timeout: 60000,
    });
    this.init()
  }
  replaceStartProxy(url: string): string {
    let _url = url
    const index = _url.indexOf('/')
    const PROXY_CONFIG_STR = sessionStorage['PROXY_URL_CONFIG']
    const PROXY_CONFIG = PROXY_CONFIG_STR ? JSON.parse(PROXY_CONFIG_STR) : {}
    if (index !== -1 && PROXY_CONFIG_STR && !_url.startsWith('http')) {
      // 说明存在斜杠
      const start = _url.slice(0, index)
      if (PROXY_CONFIG && PROXY_CONFIG[start]) {
        _url = PROXY_CONFIG[start] + _url.slice(index)
      }
    }
    return _url
  }
  // 初始化拦截器
  init(): void {
    // 请求接口拦截器
    this.instance.interceptors.request.use(
      (config) => {
        // 判断一下是否有cookie 如果有的话则把cookie放入请求头中
        const token = toolFunction.$sysTemInfo.getToken()
        if (token) {
          config.headers['Authorization'] = token;
        }
        config.url = this.replaceStartProxy(config.url || '')
        return config;
      },
      (err) => {
        console.error(err);
        message.error('报错啦')
      }
    );

    // 响应拦截器
    this.instance.interceptors.response.use(
      (response) => {
        if (!response.data.success) {
          // ElMessage({
          //   type: 'error',
          //   message: response.data.message || response.data.code
          // })
          message.error(response.data.message || response.data.code)
          throw new Error(response.data.message || response.data.code);
          return Promise.reject(response.data)
        }
        return response.data;
      },
      (error) => {
        const { data,status } = error && error.response || {}
        console.log(error.response)
        let code = status;
        if (error && error.response ) {
          // 登陆过期
          if (code === 401) {
            if (flge401 === 0) {
              ++flge401
              oidcLogin()
            }
          } else if (code === 403) {
            message.error("403:无权限访问!")
          } else {
            message.error(data?.message || data?.error_data || '系统错误，请检查API是否正常！')
          }
        } else {
          message.error(data.message)
        }
        const err = { errCode: code, errMsg: data.message || "Error" };
        return Promise.reject(err)
      }
    );
  }
  // 返回一下
  getInterceptors(): AxiosInstance {
    return this.instance;
  }
}
