import service from '@/utils/request'
import { API_PROXY } from './commonProxy'

/*
 * @description 大屏设计器
 */
//页面管理弹框 新增
export const createPageInfo = (data: any) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/`,
    method: 'post',
    data
  })
}
/*
 * @description 大屏设计器
 */
//页面管理弹框 新增
export const copyPageInfo = (webPageId, data) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/copy/${webPageId}`,
    method: 'post',
    data
  })
}
/*
 * @description 大屏设计器
 */
//页面管理弹框 新增
export const deletePage = (ids: any) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/${ids}`,
    method: 'delete'
  })
}

//页面管理弹框 保存
export const savePageInfo = (id, data) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/${id}`,
    method: 'put',
    data
  })
}
export const getPageList = (params) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/page`,
    method: 'get',
    params
  })
}
export const getPageListList = (params) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/list`,
    method: 'get',
    params
  })
}
// /api/manager/webPageGroup/list
// 获取分组
export const getPageGroupList = (params = {}) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPageGroup/list`,
    method: 'get',
    params
  })
}
// 删除页面
export const deleteCurrentPage = (data) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage`,
    method: 'delete',
    data
  })
}

// 发布
export const releasePageInfo = (data) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/publish`,
    method: 'put',
    data
  })
}

// 获取页面标签列表参数
export const pageLabel = (params) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/GroupName?`,
    method: 'get',
    params
  })
}


// 新增page分组
///api/manager/webPageGroup

export const addPageGroup = (data) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPageGroup`,
    method: 'post',
    data
  })
}

// 修改page分组
///api/manager/webPageGroup

export const editPageGroup = (data, id) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPageGroup/${id}`,
    method: 'put',
    data
  })
}
// 删除page分组
///api/manager/webPageGroup

export const deletePageGroup = (groupId) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPageGroup/${groupId}`,
    method: 'delete'
  })
}

// 发布页面
export const publishPage = (id, params = {}) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/publish/${id}`,
    method: 'put',
    params
  })
}

export const getPageItemInfo = (id) => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPage/${id}`,
    method: 'get'
  })

}

export const importPageFile=(data, params)=>{
    return service.request({
      url: `${API_PROXY}/api/webPage/import`,
      method: 'post',
      data,
      params
    })
  }
  

// 获取发布历史
export const getPublishHistoryList =(webPageId: string)=>{
  return service.request({
    url: `${API_PROXY}/api/manager/publishHistory/${webPageId}`,
    method: 'get'
  })
}