<template>
  <div class="object-style">
    <SchemaItem
      v-bind="$attrs"
      v-for="item in schemaItemList"
      :key="item.path"
      :data="data"
      :name="item.name"
      :prefix="prefix"
      :editor-id="editorId"
      @lock="handleLock"
      @unlock="handleUnlock"
      @updateName="updateParamsName"
      @add="handleAdd"
      @delete="handleDelete"
    />
  </div>
</template>

<script>
import { computed, reactive, toRefs, defineAsyncComponent, watch } from 'vue'

const SchemaItem = defineAsyncComponent(() => import('./SchemaItem'))

export default {
  name: 'SchemaObject',
  components: {
    SchemaItem
  },
  props: {
    prefix: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    editorId: {
      type: String,
      default: 'editor_id'
    }
  },
  setup(props) {
    const state = reactive({
      schemaItemList: [],
      isLock: false
    })
    const propertyKeys = computed(() => props.data.properties ? Object.keys(props.data.properties) : [])

    const initSchemaItemList = () => {
      state.schemaItemList = propertyKeys.value?.map(key => ({
        name: key,
        path: [...props.prefix, key].join('.')
      }))
    }
    initSchemaItemList()

    const updateParamsName = ({ oldName, newName }) => {
      const _index = state.schemaItemList?.findIndex(item => item.name === oldName)
      state.schemaItemList.splice(_index, 1, {
        name: newName,
        path: [...props.prefix, newName].join('.')
      })
      handleUnlock()
    }

    const handleAdd = ({ prevName, newName }) => {
      let _index = prevName
        ? state.schemaItemList?.findIndex(item => item.name === prevName) + 1
        : 0
      state.schemaItemList.splice(_index, 0, {
        name: newName,
        path: [...props.prefix, newName].join('.')
      })
    }

    const handleDelete = (name) => {
      const _index = state.schemaItemList?.findIndex(item => item.name === name)
      state.schemaItemList.splice(_index, 1)
    }

    const handleLock = () => {
      state.isLock = true
    }
    const handleUnlock = () => {
      state.isLock = false
    }

    watch(() => propertyKeys.value.length, (value) => {
      if (value === 1) {
        initSchemaItemList()
      }
    },{
      immediate: true
    })

    return {
      ...toRefs(state),
      propertyKeys,
      handleAdd,
      handleLock,
      handleUnlock,
      handleDelete,
      updateParamsName,
    }
  }
}
</script>

<style scoped>

</style>
