<template>
  <a-modal
    width="60%"
    :title="title"
    :visible="visible"
    ok-text="确定"
    @cancel="handleCancel"
    @ok="handleConfirm"
    :afterClose="afterClose"
  >
    <a-tabs v-model:activeKey="activeKey" type="card" v-if="!readOnly">
      <a-tab-pane
        v-for="item in tabsMap"
        :key="item.key"
        :tab="item.tab"></a-tab-pane>
    </a-tabs>
    <div class="cm-box">
      <div ref="cmRef" class="cm-editor"/>
    </div>
  </a-modal>
</template>

<script>
import { nextTick, onMounted, reactive, ref, toRefs, watch, watchEffect } from 'vue'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { oneDark, oneDarkHighlightStyle } from '@codemirror/theme-one-dark'
import { EditorState, } from '@codemirror/state'
import { linter, lintGutter } from '@codemirror/lint'
import { indentOnInput, syntaxHighlighting } from '@codemirror/language'
import { EditorView, keymap, lineNumbers, } from '@codemirror/view'
import { formatJsonStr } from '../../utils/tools'
import { IMPORTANT_TYPE_JSON, IMPORTANT_TYPE_JSON_SCHEMA } from '@/utils/json-schema'
import { defaultKeymap, history, historyKeymap, insertTab } from '@codemirror/commands'
import { autocompletion } from '@codemirror/autocomplete'

const tabsMap = [
  { key: IMPORTANT_TYPE_JSON, tab: 'Json格式' },
  { key: IMPORTANT_TYPE_JSON_SCHEMA, tab: 'JsonSchema格式' }
]
export default {
  name: 'index',
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: '' },
    readOnly: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['confirm', 'update:visible'],
  setup(props, { emit }) {
    const cmRef = ref()
    const state = reactive({
      tabsMap,
      activeKey: IMPORTANT_TYPE_JSON,
      code: '',
      cmEditor: null
    })

    watch(() => props.visible, () => {
      if (props.visible) {
        if (state.cmEditor) {
          try {
            state.code = JSON.stringify(props.data, null, 2)
          } catch (e) {
            state.code = ''
          }
          state.cmEditor?.dispatch({
            changes: { from: 0, to: state.cmEditor.state.doc.length, insert: state.code }
          })
        } else {
          nextTick(() => {
            initEditor()
          })
        }
      }
    }, {
      immediate: true
    })

    const getCmState = (value) => {
      return EditorState.create({
        doc: formatJsonStr(value),
        extensions: [
          json(),
          history(),
          indentOnInput(),
          EditorState.tabSize.of(2),
          keymap.of([
            ...defaultKeymap,
            ...historyKeymap,
            {
              key: 'Tab',
              preventDefault: true,
              run: insertTab,
            },
          ]),
          lineNumbers(),
          lintGutter(),
          syntaxHighlighting(oneDarkHighlightStyle),
          autocompletion({ activateOnTyping: true }),
          syntaxHighlighting(oneDarkHighlightStyle),
          EditorView.editable.of(!props.readOnly),
          EditorView.updateListener.of((v) => {
            state.code = v.state.doc.toString()
          }),
        ],
      })
    }

    const initEditor = () => {
      try {
        state.code = JSON.stringify(props.data, null, 2)
      } catch (e) {
        state.code = ''
      }
      if (!cmRef.value || !!state.cmEditor) return
      state.cmEditor = new EditorView({
        state: getCmState(state.code),
        parent: cmRef.value,
      })
    }

    onMounted(() => {
      // initEditor()
    })

    const afterClose = () => {
      state.activeKey = IMPORTANT_TYPE_JSON
      handleCancel()
    }

    const handleCancel = () => {
      emit('update:visible', false)
    }

    const handleConfirm = () => {
      if (props.readOnly) {
        handleCancel()
      } else {
        emit('confirm', {
          value: state.code,
          type: state.activeKey
        })
      }
    }

    return {
      ...toRefs(state),
      handleCancel,
      handleConfirm,
      afterClose,
      cmRef,
    }
  }
}
</script>

<style scoped lang="less">
.cm-box {
  text-align: left;
  height: 500px;
  position: relative;

  :deep .cm-editor {
    min-height: 400px;
    height: 100%;
  }
}
</style>
