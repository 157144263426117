export const stringFormatter = (code: string): string => {
  let _code
  try {
    _code = JSON.parse(code)
  } catch (e) {
    return code
  }
  if (typeof _code === 'string') {
    return stringFormatter(_code)
  }
  if (typeof _code === 'object') {
    return JSON.stringify(_code, null, 2)
  }
}