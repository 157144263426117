import { IQueryRow } from '@/views/homeCatalogue/api-manage/api-service-detail/typing'
import { message } from 'ant-design-vue'
import { set, isArray, isObject, cloneDeep } from 'lodash'

type PropertyType = 'string' | 'number' | 'integer' | 'boolean' | 'object' | 'array'

export const typeDefaultValue = {
  string: '',
  number: 0,
  integer: 0,
  boolean: false,
}

export const IMPORTANT_TYPE_JSON = 'JSON'
export const IMPORTANT_TYPE_JSON_SCHEMA = 'JSON-SCHEMA'

export const uuid = () => {
  return Math.random().toString(16).slice(2, 5)
}

//校验是否有properties
export const hasProperties = (obj: Record<string, any>): boolean => {
  const { properties, type } = obj || {}
  if (!type) return false
  if (obj.type === 'object') {
    if (!properties) return false
    for (let key of Object.keys(properties)) {
      if (properties[key].type === 'object') {
        if (!hasProperties(properties[key])) return false
      } else if (properties[key].type === 'array') {
        if (!hasProperties(properties[key].items)) return false
      } else if (!properties[key].type) {
        return false
      }
    }
  }
  if (obj.type === 'array') {
    const { items } = obj
    if (!items) return false
    if (['object', 'array'].includes(items.type)) {
      return hasProperties(items)
    } else if (!items.type) {
      return false
    }
  }
  return true
}

export const checkJsonString = (value: string | null): Record<string, any> | boolean => {
  if (!value) {
    message.error('导入JSON不得为空')
    return false
  }
  let _value
  try {
    _value = JSON.parse(value)
  } catch (err) {
    message.error('JSON格式不正确')
    return false
  }
  return _value
}

export const jsonSchemaToJson = (data: Record<string, any>) => {
  const result = {}
  const { properties } = data
  const obj = properties ? properties : data
  for (let key of Object.keys(obj)) {
    const { type, defaultValue } = obj[key]
    const _path = [key]
    const _type = type.toLowerCase() as PropertyType
    if (_type === 'object') {
      set(result, _path, jsonSchemaToJson(obj[key]))
    } else if (_type === 'array') {
      const { items } = obj[key]
      let valueArr
      if (items.type === 'object') {
        valueArr = [jsonSchemaToJson(items)]
      } else {
        valueArr = items.defaultValue || []
      }
      set(result, _path, valueArr)
    } else {
      set(result, _path, defaultValue || typeDefaultValue[_type])
    }
  }
  return result
}


export const jsonToJsonSchema = (jsonObj: Record<string, any>, isDefault: boolean = true) => {
  const schema = {
    type: 'object',
    title: 'title',
    required: [],
    properties: {},
  }
  const toJsonSchema = (path: Array<string>, value: Record<string, any>) => {
    for (let key of Object.keys(value)) {
      const _path = [...path, key]
      if (typeof value[key] !== 'object') {
        const obj: Record<string, any> = {
          type: typeof value[key],
        }
        isDefault && (obj.defaultValue = value[key])
        set(schema, _path, obj)
      } else if (isArray(value[key])) {
        const obj = {
          type: 'array',
          items: {},
        }
        const _value = !value[key].length ? { items: '' } : { items: value[key][0] || '' }
        set(schema, _path, obj)
        toJsonSchema(_path, _value)
      } else if (isObject(value[key])) {
        const obj = {
          type: 'object',
          required: [],
          properties: {},
        }
        set(schema, _path, obj)
        const newPath = [..._path, 'properties']
        toJsonSchema(newPath, value[key])
      }
    }
  }
  toJsonSchema(['properties'], jsonObj)
  return schema
}

// 数组转schema结构
/**
 * @description array转jsonSchema
 * @param data array
 * @return 转jsonSchema
 */
export const arrayToJsonSchema = (data: IQueryRow[]) => {
  if (!data.length) return {}
  let defaultSchema = cloneDeep(DEFAULT_SCHEMA_RESPONSE)
  for (let item of data) {
    const { name, isRequired, ...otherProps } = item
    defaultSchema.properties[name] = otherProps
    if (isRequired) {
      defaultSchema.required.push(name)
    }
  }
  return defaultSchema
}

/**
 * @description jsonSchema转array
 * @param data jsonSchema
 * @return 转数组
 */
export const jsonSchemaToArray = (obj: Record<string, any>) => {
  const { properties, required } = obj
  if (!obj || !Object.keys(obj).length || !Object.keys(properties).length) return []
  return Object.entries(properties).map(([name, props]) => {
    const isRequired = required.includes(name)
    return {
      name,
      isRequired,
      ...(props as Record<string, any>),
    }
  })
}

export const DEFAULT_SCHEMA = {
  type: 'object',
  required: [],
  properties: {
    field_1: {
      type: 'string',
    },
  },
}

export const DEFAULT_SCHEMA_RESPONSE = {
  type: 'object',
  required: [],
  properties: {},
}

export const setJsonSchema = (value: string, isNull: boolean): Object => {
  let res
  const _clone = isNull ? cloneDeep(DEFAULT_SCHEMA_RESPONSE) : cloneDeep(DEFAULT_SCHEMA)
  try {
    res = JSON.parse(value)
  } catch (e) {
    res = _clone
  }
  return hasProperties(res) ? res : _clone
}

//随机字符串
export const randomString = (len: number, charSet: string): string => {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 '
  len = len || 20
  let randomString = ''
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length)
    randomString += charSet.substring(randomPoz, randomPoz + 1)
  }
  return randomString
}
