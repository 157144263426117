import { computed } from 'vue'
import { mapGetters, mapState, useStore, createNamespacedHelpers } from 'vuex'


type mapFunctionType = typeof mapState | typeof mapGetters

const userMapper = (mapper: Array<string>, mapFn: mapFunctionType) => {
  const store = useStore()
  const storeStateFns = mapFn(mapper)
  const storeState = {}
  Object.keys(storeStateFns).forEach((keyFn: string) => {
    const fn = storeStateFns[keyFn].bind({ $store: store })
    storeState[keyFn] = computed(fn)
  })

  return storeState
}

export const userMapState = (moduleName: string | Array<string>, mapper: Array<string> | null) => {
  let mapperFn = mapState
  let params: any = null
  if (typeof moduleName === 'string' && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapState
    params = mapper
  } else {
    params = moduleName
  }
  return userMapper(params, mapperFn)
}

// export const useGetters = (moduleName: string | null, mapper: Array<string>) => {
//   let mapperFn = mapGetters
//   if (typeof moduleName === 'string' && moduleName.length > 0) {
//     mapperFn = createNamespacedHelpers(moduleName).mapGetters
//   } else {
//     mapper = moduleName
//   }
//   return userMapper(mapper, mapperFn)
//   // return userMapper(mapper, mapGetters)
// }
