import sourceManage from '@/views/homeCatalogue/source-manage/index.vue'
import Layout from '@/layout/index.vue'

export default {
  path: '/layout-source',
  name: 'sourceManage',
  component: Layout,
  redirect: '/source-manage',
  meta: {
    title: '数据库管理',
  },
  children: [
    {
      path: '/source-manage',
      name: 'source-manage',
      component: sourceManage,
      meta: {
        title: '数据库管理',
        icon: 'icon-data-source',
        keepAlive: true
      },
    }
  ]
}