<template>
  <div class="json-schema-vue-editor" :id="domId">
    <div class="btn-box" :style="btnStyle" v-if="needImport">
      <a-button @click="handleOpenJson(false)">导入JSON</a-button>
      <a-button type="primary" @click="handleOpenJson(true)">预览</a-button>
      <slot name="extra-btn"></slot>
    </div>
    <a-row align="middle" class="json-schema-header" :style="{paddingRight:hasScroll?'16px':'0'}">
      <a-col :span="nameSpanNum" class="col-item name-item col-item-name">
        <a-row align="middle" justify="space-around">
          <a-col :span="2" class="down-style-col">
            <div @click="handleClickIcon" v-if="schemaData.type === 'object'" class="down-style">
              <CaretDownOutlined v-if="showSchema"/>
              <CaretRightOutlined v-else/>
            </div>
          </a-col>
          <a-col :span="22">
            <div class="name-input-row">
              <a-input disabled value="root"/>
              <a-tooltip
                title="全选"
                placement="top"
                class="check-all show-all">
                <a-checkbox
                  v-model:checked="checked"
                  :disabled="disabled"
                  @change="handleCheckAll"
                />
              </a-tooltip>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="spanNum" class="col-item">
        <a-select disabled value="类型"></a-select>
      </a-col>
      <template v-if="showAll">
        <!--格式-->
        <a-col :span="spanNum" class="col-item">
          <a-select disabled placeholder="格式"></a-select>
        </a-col>
        <!--最小长度-->
        <a-col :span="spanNum" class="col-item">
          <a-input-number
            :controls="false"
            disabled
            placeholder="最小长度"
          ></a-input-number>
        </a-col>
        <!--最大长度-->
        <a-col :span="spanNum" class="col-item">
          <a-input-number
            :controls="false"
            disabled
            placeholder="最大长度"
          ></a-input-number>
        </a-col>
        <!--最小值-->
        <a-col :span="spanNum" class="col-item">
          <a-input-number
            :controls="false"
            disabled
            placeholder="最小值"
          ></a-input-number>
        </a-col>
        <!--最大值-->
        <a-col :span="spanNum" class="col-item">
          <a-input-number
            :controls="false"
            disabled
            placeholder="最大值"
          ></a-input-number>
        </a-col>
        <!--值列表-->
        <a-col :span="spanNum" class="col-item">
          <a-input disabled placeholder="值列表"></a-input>
        </a-col>
      </template>
      <!--标题-->
      <a-col :span="spanNum" class="col-item">
        <a-input disabled value="标题"></a-input>
      </a-col>
      <!--默认值-->
      <a-col :span="spanNum" class="col-item" v-if="showAll">
        <a-input disabled placeholder="默认值"></a-input>
      </a-col>
      <!--操作-->
      <a-col :span="actionSpanNum" class="col-item col-item-setting">
        <a-tooltip title="添加子节点" placement="top" v-if="schemaData.type === 'object'">
          <a-button type="link" @click="handleAdd">
            <template #icon>
              <PlusSquareOutlined/>
            </template>
          </a-button>
        </a-tooltip>
      </a-col>
    </a-row>
    <Schema
      ref="schemaContentRef"
      v-show="showSchema"
      :showAll="showAll"
      :data="schemaData"
      :editor-id="editorId"
    />
    <JsonSchemaDialog
      :data="jsEditDialog.data"
      :readOnly="jsEditDialog.readOnly"
      :title="jsEditDialog.title"
      @confirm="handleImportantJson"
      v-model:visible="jsEditDialog.visible"
    ></JsonSchemaDialog>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, toRefs, watch } from 'vue'
import { PlusSquareOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons-vue'
import { uuid } from './utils/tools'
import {
  defaultInitSchemaData,
  defaultSchema,
  SCHEMA_FORMAT,
  SCHEMA_TYPE
} from './utils/const'
import { LOOK_SOURCE_CODE } from './utils/const'
import './jsonSchema.less'
import { cloneDeep } from 'lodash'
import Schema from './components/Schema'
import JsonSchemaDialog from './components/JsonSchemaDialog'
import {
  checkJsonString,
  hasProperties,
  IMPORTANT_TYPE_JSON_SCHEMA,
  jsonSchemaToJson,
  jsonToJsonSchema
} from '@/utils/json-schema'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'

export default {
  name: 'JsonSchema',
  components: {
    Schema,
    JsonSchemaDialog,
    PlusSquareOutlined, CaretDownOutlined, CaretRightOutlined,
  },
  props: {
    schema: {
      type: Object,
      default: () => ({})
    },
    needImport: { type: Boolean, default: false },
    showAll: { type: Boolean, default: false },
    btnStyle: {
      type: Object,
      default: () => ({})
    },
    maxHeight: { type: Number, default: 600 }
  },
  emits: ['change', 'update:schema'],
  setup(props, { emit }) {
    const store = useStore()
    const cmRef = ref()
    const schemaContentRef = ref()
    const state = reactive({
      editorId: uuid(),
      checked: false,
      disabled: false,
      showSchema: true,
      schemaData: props.schema || cloneDeep(defaultInitSchemaData),
      SCHEMA_FORMAT,
      SCHEMA_TYPE,
      LOOK_SOURCE_CODE,
      jsEditDialog: {
        data: {},
        visible: false,
        title: '',
        readOnly: false,
      },
      hasScroll: false,
      observer: null,
    })
    const domId = computed(() => `schema_${state.editorId}`)
    const nameSpanNum = computed(() => props.showAll ? 4 : 8)
    const actionSpanNum = computed(() => props.showAll ? 2 : 4)
    const spanNum = computed(() => props.showAll ? 2 : 4)

    watch(() => state.schemaData, (value) => {
      emit('change', value)
    }, { deep: true })

    // nextTick 解决嵌套对象属性无法刷新页面问题
    const updateJsonSchema = (data, isInit = true) => {
      const temp = data || state.schemaData
      isInit && (state.schemaData = {})
      nextTick(() => {
        state.schemaData = temp
      })
    }
    //传参给 父组件
    const handleEmitChange = schema => {
      emit('update:schema', schema)
    }

    const handleClickIcon = () => {
      state.showSchema = !state.showSchema
    }
    //点击 全选
    const handleCheckAll = e => {
      store.commit('jsonSchema/HANDLE_CHECK_ALL', { id: state.editorId, value: state.checked })
    }
    //新增
    const handleAdd = () => {
      const newName = 'field_' + uuid()
      state.schemaData.properties[newName] = cloneDeep(defaultSchema.string)
      schemaContentRef.value.handleAdd(newName)
    }

    //打开 json 弹框
    const handleOpenJson = isPreview => {
      state.jsEditDialog.visible = true
      state.jsEditDialog.title = isPreview ? '预览' : '导入JSON'
      state.jsEditDialog.readOnly = !!isPreview
      if (isPreview) {
        state.jsEditDialog.data = jsonSchemaToJson(state.schemaData)
      } else {
        state.jsEditDialog.data = {}
      }
    }

    //导入json
    const handleImportantJson = ({ value, type }) => {
      const _value = checkJsonString(value)
      if (!_value) return
      //区分json 和 json schema类型
      if (type === IMPORTANT_TYPE_JSON_SCHEMA) {
        saveJsonSchema(_value)
      } else {
        const schema = jsonToJsonSchema(_value)
        saveJsonSchema(schema)
      }
    }

    //保存（JsonSchema 格式）
    const saveJsonSchema = value => {
      if (!hasProperties(value)) {
        message.error('JSON中缺少必要参数 - 必须包含：type、properties')
        return
      }
      state.jsEditDialog.visible = false
      updateJsonSchema(value)
      handleEmitChange(value)
    }

    const afterAdd = () => {
      const dom = schemaContentRef.value.$el
      const schemaHeight = dom.clientHeight
      if (schemaHeight < 600) return
      const scrollTop = dom.scrollTop
      nextTick(() => {
        dom.scrollTo({
          left: 0,
          top: scrollTop + 40,
          behavior: 'smooth'
        })
      })
    }

    onMounted(() => {
      schemaContentRef.value.$el.style.maxHeight = `${props.maxHeight}px`

      let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
      let schemaRef = document.getElementById(domId.value)
      state.observer = new MutationObserver((e) => {
        const height = schemaRef.clientHeight
        state.hasScroll = height >= 642
      })
      nextTick(() => {
        state.observer.observe(schemaRef, {
          childList: true,
          attributes: true,
          attributeFilter: ['style'],
          attributeOldValue: true,
          subtree: true
        })
      })
      store.commit('jsonSchema/SET_SCROLL_FUNCTION', { id: state.editorId, func: afterAdd })
    })
    onUnmounted(() => {
      store.commit('jsonSchema/CLEAR_ALL_REQUIRED', state.editorId)
      store.commit('jsonSchema/CLEAR_SCROLL_FUNCTION', state.editorId)
      if (state.observer) {
        state.observer.disconnect()
        state.observer.takeRecords()
        state.observer = null
      }
    })

    return {
      cmRef,
      schemaContentRef,
      nameSpanNum,
      actionSpanNum,
      spanNum,
      domId,
      ...toRefs(state),
      handleAdd,
      handleClickIcon,
      handleCheckAll,
      updateJsonSchema,
      handleImportantJson,
      handleOpenJson,
    }
  }
}
</script>

<style scoped lang="less">
.json-schema-vue-editor {
  position: relative;
  //width: 100%;
  //box-sizing: border-box;

  .json-schema-header {
    padding: 0 10px 10px 0;
  }

  .schema-content {
    overflow-y: auto;
  }

  .btn-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 16px;

    .ant-btn {
      margin-right: 10px;
    }
  }

  .cm-editor {
    margin-top: 60px;
    text-align: left;
  }
}
</style>
