import { HttpServer } from '@/http'
import { API_PROXY } from './commonProxy'


class LargeDataViews {
  root: HttpServer

  constructor(root: HttpServer) {
    this.root = root
  }

  transformRule(data) {
    return this.root.request({
      url: `${API_PROXY}/api/dyncApi/transfer`,
      method: 'put',
      data
    })
  }

  // 加载数据源列表
  getDataSourceList(params) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource`,
      method: 'get',
      params
    })
  }

  // 删除数据源
  deleteDataSource(data) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource`,
      method: 'delete',
      data
    })
  }

  // 查询数据源
  queryDataSource(id: string | number) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/${id}`,
      method: 'get',
    })
  }

  // 添加数据源
  addDataSource(data) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource`,
      method: 'post',
      data
    })
  }

  //编辑数据源
  editDataSource(id, data) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/${id}`,
      method: 'put',
      data
    })
  }

  // 校验数据源
  calibrateDataSource(data) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/check`,
      method: 'post',
      data
    })
  }

  // 获取表名列表
  getDBTableList(id: string) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/tableList/${id}`,
      method: 'get',
    })
  }

  // 获取表名 字段名列表
  getDBTableParamsList(id: string, tableName: string) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/tableList/${id}/${tableName}`,
      method: 'get',
    })
  }

  //数据MOD 弹框 点击测试
  handleTest(id, data) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/runTestCreateSql/${id}`,
      method: 'post',
      data
    })
  }

  //数据MOD 弹框 保存数据
  handleSave(data) {
    return this.root.request({
      url: `${API_PROXY}/api/dataResource/saveSystemCreateSql`,
      method: 'post',
      data
    })
  }

  //获取当前当前组件data mod 的配置信息
  getModData(id: string) {
    return this.root.request({
      url: `${API_PROXY}/api/dyncApi/${id}`,
      method: 'get',
    })
  }

}

export default LargeDataViews
