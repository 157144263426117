
import PageManage from './pageManage'
import httpClient, { HttpServer } from '@/http/index';
import { AxiosRequestConfig, AxiosPromise } from 'axios';
import { Iparam, IBodyData, IHttpClient } from '@/types';
import JoinTeamManage from "@/api/joinTeamManage";

import dataSourceManage from './dataSourceManage';
import GroupManage from './groupManage';

class Api implements IHttpClient {
  request(config: AxiosRequestConfig): AxiosPromise<any> {
    return this.httpClient.request(config)
  }
  joinTeamManage: JoinTeamManage
  httpClient: HttpServer
  pageManage: PageManage
  dataSourceManage: dataSourceManage
  groupManage: GroupManage

  constructor() {
    this.httpClient = httpClient
    // 以模块划分api
    this.pageManage = new PageManage(httpClient)
    // 商品列表+详情
    // 加入团队
    this.joinTeamManage = new JoinTeamManage(httpClient)
    // 数据源管理 
    this.dataSourceManage = new dataSourceManage(httpClient)
    // 分组管理 
    this.groupManage = new GroupManage(httpClient)
  }
  // 代理模式:get post put
  get<T>(url: string, param?: Iparam, config?: AxiosRequestConfig): AxiosPromise<T> {
    return httpClient.get(url, param, config)
  }
  /**
 *
 * @param url 请求的资源
 * @param data 数据包
 * @param param 问号参数
 * @param config axios的config参数
 */
  post<T>(url: string, data?: IBodyData, param?: Iparam, config?: AxiosRequestConfig): AxiosPromise<T> {
    return httpClient.post(url, data, param, config)
  }
  /**
   *
   * @param url 请求的资源
   * @param data 数据包
   * @param param 问号参数
   * @param config axios的config参数
   */
  put<T>(url: string, data?: IBodyData, param?: Iparam, config?: AxiosRequestConfig): AxiosPromise<T> {
    return httpClient.post(url, data, param, config)
  }

}
export default new Api()

// 12312312312312