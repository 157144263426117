import { createApp, h } from "vue"
import App from './app.vue'
import router from './router'
import store from './store/'
import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.less'
// import 'ant-design-vue/dist/antd.variable.less'
import 'ant-design-vue/dist/antd.variable.less'
import "./styles/main.less"
// import "./styles/main.css"
import 'driver.js/dist/driver.min.css';
// import { driversOpen } from '@/utils/driver'

// 地图数据
import '../src/utils/china'

if (!sessionStorage['token']) { // token不存在就删除掉租户信息
  sessionStorage.removeItem('tenant_code')
}
// import dark from 'ant-design-vue/dist/theme-dark';

// 初始化引导页
// driversOpen()

const app = createApp({
  render: () => h(App),
  setup(){
    
  }
})

app.use(Antd).use(store).use(router).mount('#app')
window.onerror = (mes: string, source, lineno, colno, error) => {
  if (mes === 'ResizeObserver loop limit exceeded') {
    console.warn('Ignored: ResizeObserver loop limit exceeded');
    return false;
  } else {
    console.warn("前端报文：" + mes);
    return false;
  }
}