export const getLocalItem = (key: string) => {
  return key && localStorage.getItem(key)
}

export const setLocalItem = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const removeLocalItem = (key: string) => {
  localStorage.removeItem(key)
}
