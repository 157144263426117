class SysTem {
  constructor() {
  }

  setTenant(value) {
    sessionStorage['tenant_code'] = value
  }

  getTenant() {
    return sessionStorage['tenant_code']
  }

  removeTenant() {
    return sessionStorage.removeItem('tenant_code')
  }

  setTenantPlat(value) {
    sessionStorage['tenantPlat'] = value
  }

  getTenantPlat() {
    return 'saas' //默认为saas
    return sessionStorage['tenantPlat']
  }

  removeTenantPlat() {
    return sessionStorage.removeItem('tenantPlat')
  }

  setMenu(list) {
    sessionStorage['menu'] = JSON.stringify(list)
  }

  getMenu() {
    return sessionStorage['menu']
  }

  removeMenu() {
    sessionStorage.removeItem('menu')
  }

  setTenantList(list) {
    sessionStorage['tenantList'] = JSON.stringify(list)
  }

  getTenantList() {
    return sessionStorage['tenantList']
  }

  removeTenantList() {
    sessionStorage.removeItem('tenantList')
  }

  setUserInfo(obj) {
    sessionStorage['user_info'] = JSON.stringify(obj)
  }

  getUserInfo() {
    return sessionStorage['user_info']
  }

  removeUserInfo() {
    sessionStorage.removeItem('user_info')
  }

  setLoginOidcStatus(num) {
    sessionStorage['login-oidc-status'] = num
  }

  getLoginOidcStatus() {
    return sessionStorage['login-oidc-status']
  }

  removeLoginOidcStatus() {
    sessionStorage.removeItem('login-oidc-status')
  }

  setIdToken(str) {
    sessionStorage['id-token'] = str
  }

  getIdToken() {
    return sessionStorage['id-token']
  }

  removeIdToken() {
    sessionStorage.removeItem('id-token')
  }

  setToken(str) {
    sessionStorage['token'] = str
  }

  getToken() {
    return sessionStorage['token']
  }

  removeToken() {
    sessionStorage.removeItem('token')
  }

  cleanSystemInfo() {
    ['token', 'id-token', 'login-oidc-status', 'user_info', 'menu', 'tenantList', 'lastPath', 'signinCallbackUrl'].forEach(key => {
      sessionStorage.removeItem(key)
    })
  }

  setLastPath(path) {
    sessionStorage['lastPath'] = path
  }

  getLastPath() {
    return sessionStorage['lastPath']
  }

  removeLastPath() {
    sessionStorage.removeItem('lastPath')
  }

  setSignOutRedirectPath(path) {
    sessionStorage['signOutRedirectPath'] = path
  }

  getSignOutRedirectPath() {
    return sessionStorage['signOutRedirectPath']
  }

  removeSignOutRedirectPath() {
    sessionStorage.removeItem('signOutRedirectPath')
  }

  /**
   * 0或者1 在退出登录页面 signout-oidc 判断这个标示,如果有则继续调整登录
   * @param {*} identify
   */
  setSignOutAfterIsReSign(identify) {
    sessionStorage['signOutAfterIsReSignIdentify'] = identify
  }

  getSignOutAfterIsReSign() {
    return sessionStorage['signOutAfterIsReSignIdentify']
  }

  removeSignOutAfterIsReSign() {
    sessionStorage.removeItem('signOutAfterIsReSignIdentify')
  }

  // 设置登录后要跳转的页面
  setSigninCallbackUrl(url) {
    sessionStorage['signinCallbackUrl'] = url
  }

  getSigninCallbackUrl() {
    return sessionStorage['signinCallbackUrl']
  }

  removeSigninCallbackUrl() {
    sessionStorage.removeItem('signinCallbackUrl')
  }

  getGroupId() {
    return sessionStorage['groupId']
  }

  setGroupId(groupId) {
    sessionStorage['groupId'] = groupId
  }
  getPageId() {
    return sessionStorage['pageId']
  }
  getDefaultTenant(){
    return window.PROXY_URL_CONFIG.defaultTenant||''
  }
}

export default new SysTem()
