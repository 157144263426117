

const PROXY_URL_CONFIG= window.PROXY_URL_CONFIG
export const OAUTH_PROXY =PROXY_URL_CONFIG.OAUTH_PROXY// "OAUTH_PROXY"
export const MAIN_PROXY = PROXY_URL_CONFIG.MAIN_PROXY
export const TOOL_PROXY =PROXY_URL_CONFIG.TOOL_PROXY
export const USERRIGHT_PROXY = PROXY_URL_CONFIG.USERRIGHT_PROXY// 'USERRIGHT_PROXY'
export const COMMON_PROXY =PROXY_URL_CONFIG.COMMON_PROXY// 'COMMON_PROXY'
export const API_PROXY = PROXY_URL_CONFIG.API_PROXY
export const USERRIGHTAPI = PROXY_URL_CONFIG.userRightAPI

