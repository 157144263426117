import { HttpServer } from '@/http'
import { API_PROXY } from './commonProxy'
import { IApiOptions } from '@/types'


class GroupManage {
  root: HttpServer
  moduleName: string

  constructor(root: HttpServer) {
    this.root = root
  }

  getGroupList() {
    return this.root.request({
      url: `${API_PROXY}/api/apiGroup`,
      method: 'GET'
    })
  }

  // 查看分组
  getGroup(id) {
    return this.root.request({
      url: `${API_PROXY}/api/apiGroup/${id}`,
      method: 'GET'
    })
  }

  // 新增分组
  newGroup(data) {
    return this.root.request({
      url: `${API_PROXY}/api/apiGroup`,
      method: 'post',
      data
    })
  }

  // 编辑分组
  editGroup(data, id) {
    return this.root.request({
      url: `${API_PROXY}/api/apiGroup/${id}`,
      method: 'put',
      data
    })
  }

  // 删除分组 
  delGroup(data) {
    return this.root.request({
      url: `${API_PROXY}/api/apiGroup`,
      method: 'delete',
      data
    })
  }

  // 保存分组
  dropUpdate(data){
    return this.root.request({
      url: `${API_PROXY}/api/apiGroup/dropUpdate`,
      method: 'put',
      data
    })
  }
}

export default GroupManage
