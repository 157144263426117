import { DisplayField, ISelectOptions, SortRule, SupportComponentType } from './types'

export const sortRules: ISelectOptions[] = [
  { label: '正序', value: SortRule.Asc },
  { label: '倒序', value: SortRule.Desc },
]

export const sortByOptions: ISelectOptions[] = [
  { label: '横轴值', value: '0' },
  { label: '纵轴值', value: '1' }
]

export const sortByOptionsP: ISelectOptions[] = [
  { label: '扇区字段值', value: '0' },
  { label: '扇区数值', value: '1' },
]

export const chartsTypeMap: Record<string, string> = {
  [SupportComponentType.Line]: '折线图',
  [SupportComponentType.Pie]: '饼图',
  [SupportComponentType.Bar]: '柱状图',
  [SupportComponentType.FLAP]: '翻牌器',
}

export const yAxisFieldOptions: ISelectOptions[] = [
  { label: '统计记录总数', value: DisplayField.Count },
  { label: '统计字段数值', value: DisplayField.Comput },
]

export const computeModeMap: ISelectOptions[] = [
  { label: '求和', value: 'sum' },
  { label: '最大值', value: 'max' },
  { label: '最小值', value: 'min' },
  { label: '平均值', value: 'avg' },
  { label: '自定义', value: 'custom' },
]
