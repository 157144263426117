const HSOAUTH_CLIENT_URI = 'http://47.110.145.204:8256'
const CONNECT_AUTHORIZE = '/connect/authorize'
const HS_CONNECT_TOKEN = '/connect/token'
const HSOAUTH_CLIENT_CONNECT_AUTHORIZE = HSOAUTH_CLIENT_URI + CONNECT_AUTHORIZE


const HSOAUTH_CLIENT_ID_CODE = 'huansi_local2'
const HSOAUTH_CLIENT_SECRET_CODE = 'huansi.net'
const HSOAUTH_REDIRECT_URI_CODE = 'http://localhost:50001/signin-oidc'
const HSOAUTH_SCOPE_CODE = 'UserRight'
const HSOAUTH_RESPONSE_MODE='query'
const HSOAUTH_CODE_CHALLENGE_METHOD='S256'
const HSOAUTH_RESPONSE_TYPE_CODE='code'
// 简化模式
const HSOAUTH_CLIENT_ID_IMLICT = 'implict_test'
const HSOAUTH_REDIRECT_URI_IMLICT = 'http://localhost:50001/signin-oidc'
const HSOAUTH_RESPONSE_TYPE_IMLICT='id_token token'
const HSOAUTH_SCOPE_IMLICT='openid profile UserRight'
const HSOAUTH_POST_LOGOUT_REDIRECT_URI_IMLICT="http://dev-hangzhou.frame3.saas.huansi.net/signout-oidc"
export default  {
  HSOAUTH_CLIENT_URI,
  CONNECT_AUTHORIZE,
  HSOAUTH_CLIENT_CONNECT_AUTHORIZE,
  HSOAUTH_CLIENT_ID_IMLICT,
  HSOAUTH_REDIRECT_URI_IMLICT,
  HSOAUTH_CLIENT_ID_CODE,
  HSOAUTH_CLIENT_SECRET_CODE,
  HSOAUTH_REDIRECT_URI_CODE,
  HSOAUTH_SCOPE_CODE,
  HSOAUTH_RESPONSE_MODE,
  HSOAUTH_CODE_CHALLENGE_METHOD,
  HSOAUTH_RESPONSE_TYPE_CODE,
  HSOAUTH_RESPONSE_TYPE_IMLICT,
  HSOAUTH_SCOPE_IMLICT,
  HS_CONNECT_TOKEN,
  HSOAUTH_POST_LOGOUT_REDIRECT_URI_IMLICT
}
