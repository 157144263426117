import { Condition, ComputeSign } from '@/enums'

/**
 * 支持的组件类型
 */
export enum SupportComponentType {
  Bar = 'chart-bar',
  Line = 'chart-line',
  Pie = 'chart-pie',
  FLAP = 'flap'
}

/**
 * 排序依据
 */
export enum SortAs {
  X = '0',
  Y = '1'
}

/**
 * 排序规则
 */
export enum SortRule {
  Asc = 'asc',
  Desc = 'desc'
}

/**
 * 纵轴计算规则
 */
export enum DisplayField {
  Count = 'count',
  Comput = 'comput'
}


export interface IFilterItem {
  fieldName: string
  computeSign: ComputeSign
  fieldFilter: string | number | null
}

export interface IFieldComputeItem {
  fieldName: string //参与计算的字段名
  computeMode: string   //计算方式 枚举值
  computeModeText?: string   //计算方式 文本值
}

export interface IGConvergeConfig {
  groupConverge?: boolean // 是否开启 分组聚合
  groupAs: string // 分组的字段名
  sortAs?: string // 排序依据
  sortRule: SortRule //排序规则
}

export interface ISelectOptions {
  value: string
  label: string

  [key: string]: any
}

export interface IFormData {
  dataTable: string
  filterMethod: Condition
  filterParams: IFilterItem[]
  categoryField: string
  displayField: DisplayField
  computeMode: string
  fieldComputeList: IFieldComputeItem[]
  sortAs: string
  sortRule: SortRule
}

export interface ITestParams {
  main: {
    dataTable: string
    filterMethod: Condition
    filterDatas: IFilterItem[]
    displayField: DisplayField
    categoryField: string
    fieldComputes: IFieldComputeItem[]
    sortAs: string
    sortRule: SortRule
    groupConverge: boolean
  },
  converges: IGConvergeConfig[],
  objectFormat?: number
}

export interface IModConfig {
  pageId
}


