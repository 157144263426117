import regExp from './regExp'
import { sampleSize } from 'lodash'
import { message } from 'ant-design-vue'
import { string } from 'vue-types'

/**
 * Date format
 * format(new Date(), "yyyy-MM-dd hh:mm:ss")
 * @param {String} fmt
 * @return
 */
export const formatTime = (date: Date, fmt: string = 'yyyy-MM-dd hh:mm:ss'): string => {
  const o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  return fmt
}

// 获取SessionStorage中的值
export const getSessionItem = (key: string) => {
  const jsonValue = sessionStorage.getItem(key)
  return jsonValue && JSON.parse(jsonValue)
}

/**
 * @description 移除sessionStorage
 * @param {String} key
 */
export const deleteSessionItem = key => {
  const target = sessionStorage.getItem(key)
  target && sessionStorage.removeItem(key)
}

// 是否是正确的手机号码
export const hasPhone = (phoneNumber: number): boolean => {
  if (!regExp.phone.test(phoneNumber)) {
    return false
  }
  return true
}

// 是否是正确的邮箱
export const hasEmail = (email: string): boolean => {
  if (!regExp.email.test(email)) {
    return false
  }
  return true
}

// 是否置灰
export const isDisabled = (value: number): boolean => !value && value !== null && value !== 0

export const randomString = (length: number) => {
  const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  return sampleSize(charSet, length).toString().replace(/,/g, '')
}

export const jsonToObj = (str: string) => {
  if (!str) return
  let obj
  try {
    obj = JSON.parse(str)
  } catch (e) {
    // message.error('JSON 格式错误')
    return
  }
  return obj
}

export const formatParams = str => {
  return str ? (str === '{}' ? null : str) : null
}

export const getQueryVariable = (variable: string) => {
  const query = decodeURIComponent(window.location.search.substring(1))
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return ''
}

/**
 * getComponentsType  分割组件的类型 type
 *  value 0 chart 1 [chart, line]
 * @param {string} str // 组件类型
 * @param {number} value // 组件类型值
 * @return
 */
export const getComponentsType = (str: string, value: number = 0) => {
  const arr = str.split('-')
  if (value === 0) return str
  if (value === 1) return arr.splice(0, 2)
}

/**
 * getComponentsFormat  返回组件对应转换类型
 * 返回数据格式（1：分组二维数组、2：一维数组、3：对象数组、4：二维数组、5：多数据集对象、6：无维度多系列二维数组、7:分组聚合二维数组）
 *              柱状图/折线图     饼图      描述列表      图片      分页表格        下拉，单选，复选        柱状图/折线图/饼图
 * @param {string} type // 组件类型
 * @param apiType apiType 数据类型
 * @return
 */
export const getComponentsFormat = (type, apiType) => {
  switch (type) {
    case 'chart-line':
      // return apiType === 'mod' ? 7 : 1(弃用逻辑)
      return 1
    case 'chart-bar':
      // return apiType === 'mod' ? 7 : 1(弃用逻辑)
      return 1
    case 'chart-pie':
      return 2
    case 'chart-pie3D':
      return 1
    case 'chart-liquidFill':
      return 1
    case 'chart-heatmap':
      return 2
    case 'chart-chinaMap':
      return 3
    case 'table':
      return 4
    case 'tabs':
      return 1
    case 'text':
      return 1
    case 'complexText':
      return 7
    case 'carouselText':
      return 1
    case 'carouselImage':
      return 1
    case 'time':
      return 4
    case 'flap':
      return 4
    case 'liquid':
      return 1
    case 'form-input':
      return 4
    case 'form-select':
      return 6
    case 'form-rangePicker':
      return 2
    case 'form-inputNumber':
      return 4
    case 'form-datePicker':
      return 4
    case 'form-radio':
      return 6
    case 'form-checkbox':
      return 6
    case 'form-button':
      return 4
    case 'antd-table':
      return 5
    case 'image':
      return 3
    case 'outExcel':
      return 3
    case 'outPrint':
      return 3
    case 'board-list':
      return 1
    case 'watch':
      return 1
    case 'data':
      return 3
    case 'descriptions':
      return 3
    case 'platform-list':
      return 3
    case 'station-list':
      return 3
    case 'foreign-list':
      return 3
    case 'team-kanban-info':
      return 3
    case 'main-concepts':
      return 0
    case 'tailor-list':
      return 3
    case 'thermodynamic':
      return 3
    case 'lineBarCharts':
      return 5
    case 'machineEfficiency':
      return 3
    case 'devHorizontalMarketInformation':
      return 3
      case 'flipperAnimate':
        return 5
    default:
      return 1
  }
}

/**
 *
 * 更具 格式转换数据
 */
export const converData = (data, format) => {
  if (format == 2) {
    for (let item in data) {
      if (!isNaN(data[item][1])) data[item][1] = +data[item][1]
    }
  }
  // else if (format == 1) {
  //   // format == 1 折线图/柱状图  二维数组里面的值 都转换成浮点数
  //   // 从 二维数组的 1 指针位开始 每个数组的 1 指针位 开始把后面的字符串 都转换成 浮点数
  //   for (let index = 1; index < data.length; index++) {
  //     const itemArr = data[index]
  //     for (let idx = 1; idx < itemArr.length; idx++) {
  //       if(!isNaN(itemArr[idx])) itemArr[idx] = +(itemArr[idx])
  //     }
  //   }
  //   return data
  // }
  return data
}

/**
 * @param {Array} treeData  树结构
 * @param {String} childrenKey 树结构子节点的key
 * @return {Array} treeData 平铺树结构
 */
export const treeToArray = <T>(treeData: T, childrenKey: string = 'children') => {
  const result = []
  const expanded = data => {
    if (data && data.length) {
      data.forEach(item => {
        result.push(item)
        expanded(item[childrenKey] || [])
      })
    }
  }
  expanded(treeData)
  return result
}

/**
 * @description 延迟函数
 * @param delay 延迟时间
 * @returns {Promise}
 */
export const sleep = (delay?: number): Promise<any> => new Promise(cb => setTimeout(cb, delay))

/**
 * @description 数字转千分位（四舍五入）
 * @param {Number} minimumFractionDigits 最少保留几位小数， 默认为0（如果是整数的话、则不显示小数位）
 * @param {Number} maximumFractionDigits 最多保留几位小数
 * @return {String}
 */

export const numberToThousands = (
  number: number,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 2
): string => {
  return number.toLocaleString('zh', {
    minimumFractionDigits,
    maximumFractionDigits,
  })
}

/**
 * @description 是否到底部
 * @param {Element} wrapperNode 包裹容器节点
 * @param {Number} tbodyWrapperNode 滚动容器高度
 * @param {Number}  distance  距离触发点
 */
export const isBottoming = (
  wrapperNode: HTMLParagraphElement,
  tbodyWrapperNode: HTMLParagraphElement,
  distance: number = 5
): boolean => {
  // const { height: scrollHeight } = tbodyWrapperNode?.getBoundingClientRect() as DOMRect
  const scrollHeight = tbodyWrapperNode.scrollHeight
  // console.log(
  //   'params scrollTop clientHeight scrollHeight',
  //   wrapperNode.scrollTop,
  //   wrapperNode.clientHeight,
  //   Math.round(scrollHeight)
  // )
  return Math.round(wrapperNode.scrollTop) + Math.round(wrapperNode.clientHeight) >= Math.round(scrollHeight - distance)
}

// 移除登录信息
export const removeInfo = () => {
  deleteSessionItem('token')
  deleteSessionItem('user_info')
  deleteSessionItem('id_token')
}

// 移除所有sessionStorage
export const clearSession = () => {
  sessionStorage.clear()
}

/**
 *
 * @param {Object} data 要导出的对象
 * @param {String} fileName 导出的文件名称
 * @param {String} fileNameSuffix 导出的文件后缀
 * @param fileType
 * @param isFrontEnd
 * @returns
 */
export const downloadFile = ({
                               data,
                               fileName = '未命名的JSON文件',
                               fileNameSuffix = 'json',
                               fileType = 'text/json',
                               isFrontEnd = true,
                             }: {
  data: Record<string, any>
  fileName?: string
  fileNameSuffix?: string
  fileType?: string
  isFrontEnd?: boolean
}): void => {
  if (!data) return
  let _data: string | any
  if (typeof data === 'object' && isFrontEnd) {
    try {
      _data = JSON.stringify(data)
    } catch (e) {
      _data = 'Data is not json'
    }
  }
  if (!isFrontEnd) _data = data
  let blob = new Blob([_data], { type: fileType })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `${fileName}.${fileNameSuffix}`
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}

// 数据格式的转换
export const formatConversion = (value: string, type: string) => {
  switch (type) {
    case 'object':
      try {
        if (!value) return ' '
        let paramsValue = JSON.parse(value)
        if (Object.prototype.toString.call(paramsValue) !== '[object Object]') return false
        return paramsValue
      } catch (error) {
        return false
      }
    case 'array':
      try {
        if (!value) return ' '
        let result = value.match(/\[(.+?)\]/g)
        if (result !== null) return JSON.parse(value)
        return createArr(value)
      } catch (error) {
        return false
      }
    case 'int':
    case 'integer':
      if (!value) return ' '
      let num = parseInt(value)
      if (!Number.isNaN(num)) return num
      return false
    case 'string':
      if (!value) return ' '
      return String(value)
  }
}

export const createArr = (str: any) => {
  let arr = []
  arr = str.match(/[1-9][0-9]*/g)
  //正则匹配数字的规则
  return (arr = [...new Set(arr)])
}

// 数组去重
export const removeRepeat = (arr: number[] | string[]) => {
  let end
  arr.sort((a, b) => a - b)
  end = arr[0]
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] === end) {
      arr.splice(i, 1)
      i--
    } else {
      end = arr[i]
    }
  }
  return arr
}

// 数组对象去重
export const removeRepeatObject = arrObj => {
  let hash = {}
  return arrObj.reduce((newArr, next) => {
    hash[next.key] ? '' : (hash[next.key] = true && newArr.push(next))
    return newArr
  }, [])
}

// 跳转
export const jumpToView = (id, param?: any[]) => {
  const proxyUrlConfig = getSessionItem('PROXY_URL_CONFIG')
  // 拆分 param 为 字符串
  let pStr = ''
  const keys = param ? Object.keys(param) : []
  if (keys?.length) {
    pStr = ''
    keys.forEach((key, index) => {
      const item = param[key]
      if (typeof item !== 'object') {
        pStr += `&${key}=${item}`
      }
    })
  }
  const url = `${proxyUrlConfig.ROTATION_BI_VIEW}/main?pageId=${id}${pStr}`
  window.open(url)
}
