import { setLocalItem } from '@/utils/toolLocalStorage'
import saveToLocal from '@/utils/commonSetStorage'
import type { IApiOptions, IGeneralTitle, IObject } from '@/types'
import { EChartsOption, SeriesOption, RegisteredSeriesOption } from 'echarts'
// import { FormItemProps, FormItemInstance } from 'ant-design-vue/lib/form'
import { InputProps } from 'ant-design-vue/lib/input'

import {
  ref,
  reactive
} from 'vue'

import _ from 'lodash'
import API from '@/api'

const saveLocal = new saveToLocal()

// 位置尺寸
export interface ILocationSize {
  width?: number
  height?: number
  left?: number
  top?: number
  zIndex?: number
}

export interface IPageSize {
  width?: number
  height?: number
}

// 图表设置
// export interface IOptionData {
//   grid?: IGridData
//   label?: ILabelData
// }

export interface IGridData {
  top?: string | number
  bottom?: string | number
  left?: string | number
  right?: string | number
}

export interface ILabelData {
  show?: boolean
  color?: string
  fontSize?: number
  fontFamily?: string
}


export interface IRow {
  splitArea?: IsplitArea
  rowBackground?: string
  rowAutoHeight?: boolean
  rowHeight?: string
}

export interface IsplitArea {
  show?: boolean
}

// export interface Idataset {
//   source?: Array<any>
// }

// export interface IFormOption extends FormItemProps {
//   dataset?: Idataset
// }

export interface IChartsOption extends EChartsOption {
  row?: IRow | IRow[]
  numberLength?: number
  generalTitle?: IGeneralTitle

  [key: string]: any
}

export interface IComponentConfig {
  id?: string
  anotherName?: string
  remark?: string
  uid?: string
  type?: string
  template?: IChartsOption
  locationSize?: ILocationSize
  optionData?: IChartsOption
  action?: string
  api?: IApiOptions
  backgroundImage?: string
  backgroundColor?: string
  backgroundRepeat?: string
  backgroundPosition?: string
  backgroundSize?: string
}

export interface IState {
  infiniteRef: any,
  mainJson: any,
  selecto: any,
  echarts: any,
  componentsList: Array<any>
  moveablerRef: object
  viewerBackgroundRef: any
  viewportRef: any
  componentConfig: IComponentConfig
  pageSize: IPageSize
}

const state: IState = {
  infiniteRef: {},
  mainJson: {},
  selecto: null,
  echarts: null,
  componentsList: [],
  moveablerRef: null,
  viewerBackgroundRef: null,
  viewportRef: null,
  pageSize: {},
  componentConfig: {
    id: '',
    type: '',
    anotherName: '',
    // subType: '',
    locationSize: {},
    optionData: {},
    action: '',
    backgroundImage: '',
    backgroundColor: '',
    backgroundRepeat: '',
    backgroundPosition: '',
    backgroundSize: '',
    api: {
      paramsConfig: {
        upStream: [],
        downStream: []
      }
    }
  }
}

const mutations = {
  SET_COMPONENT_LIST: (state: IState, data: any) => {
    const { children } = data.body
    const list = children.filter(item => item.id)
    state.componentsList = list?.map(item => {
      const { id, type, target, option, staticData, anotherName } = item
      return {
        id,
        type,
        target,
        option,
        data: staticData,
        anotherName
      }
    })
  },
  SET_MAIN_JSON: (state: IState, data: any) => {
    // const mainJson = state.mainJson
    // const children = state.mainJson.body.children
    // // for (const key in object) {
    // //   if (Object.prototype.hasOwnProperty.call(object, key)) {
    // //     const element = object[key];

    // //   }
    // // }
    // if (!data.body?.children) {
    //   state.mainJson = Object.assign(mainJson, data)
    //   console.log(!data.body?.children, 1)
    // } else {
    //   console.log(!data.body?.children, 2)
    //   delete mainJson.body.children
    //   state.mainJson = Object.assign(mainJson, data)
    // }
    state.mainJson = data
  },
  SET_MAIN_JSON_API_LIST: (state: IState, data: Record<string, any>) => {
    state.mainJson.apiList = data
  },

  SET_MAIN_JSON_ATTRIBUTE: (state: IState, data: any) => {
    const { id, path, value } = data
    _.set(state.mainJson.body.children.find(item => item.id === id), path, value)
  },
  SET_MAIN_JSON_STYLE: (state: IState, data: any) => {
    const { path, value } = data
    _.set(state.mainJson.body.style, path, value)
  },
  SET_INFINITE_REF: (state: IState, ob: any) => {
    state.infiniteRef = _.clone(ob)
  },
  SET_MOVEABLER_REF: (state: IState, el: any) => {
    state.moveablerRef = el.$_moveable
  },
  SET_SELECTO: (state: IState, ob: any) => {
    state.selecto = ob
  },
  SET_LOCATION_SIZE_ATTRIBUTE: (state: IState, data: any) => {
    const { attribute, value } = data
    state.componentConfig.locationSize[attribute] = value
  },
  SET_OPTIONS_ATTRIBUTE: (state: IState, data: any) => {
    const { attribute, value } = data
    // 如果是多层级 依层级传入对应的数组
    if (Array.isArray(attribute)) {
      _.set(state.componentConfig.optionData, attribute, value)
    } else {
      state.componentConfig.optionData[attribute] = value
    }
  },
  SET_API_ATTRIBUTE: (state: IState, data: any) => {
    const { attribute, value } = data
    _.set(state.componentConfig.api, attribute, value)
  },
  SET_ANOTHER_NAME: (state: IState, data: any) => {
    _.set(state.componentConfig, 'anotherName', data)
  },
  SET_REMARK_NAME: (state: IState, data: any) => {
    _.set(state.componentConfig, 'remark', data)
  },
  SET_BACKGROUND: (state: IState, data: IObject) => {
    const {
      backgroundColor,
      backgroundImage,
      backgroundRepeat,
      backgroundPosition,
      backgroundSize
    } = data
    state.componentConfig.backgroundColor = backgroundColor
    state.componentConfig.backgroundImage = backgroundImage
    state.componentConfig.backgroundRepeat = backgroundRepeat
    state.componentConfig.backgroundPosition = backgroundPosition
    state.componentConfig.backgroundSize = backgroundSize
  },
  SET_COVER_STREAM: (state: IState, data: any) => {
    console.log('stream##########', data)
    const { stream, attribute } = data

    if (attribute === 'upStream') {
      state.mainJson.body.children.find(
        item => {
          item.api.paramsConfig[attribute]?.forEach(
            (items, index) => {
              if (items == stream?.up) item.api.paramsConfig[attribute].splice(index, 1)
            }
          )
        }
      )
      stream?.down?.forEach(item => {
        state.mainJson.body.children.find(
          items => {
            if (items.id == item) {
              items.api.paramsConfig[attribute]?.push(stream?.up)
            }
          }
        )
      })
    }
    if (attribute === 'downStream') {
      state.mainJson.body.children.find(
        item => {
          item.api.paramsConfig[attribute]?.forEach(
            (items, index) => {
              if (items == stream?.down) item.api.paramsConfig[attribute].splice(index, 1)
            }
          )
        }
      )
      stream?.up?.forEach(item => {
        state.mainJson.body.children.find(
          items => {
            if (items.id == item) {
              items.api.paramsConfig[attribute]?.push(stream?.down)
            }
          }
        )
      })
    }

  },
  GET_PAGE_BACKGROUND: (state: IState, el: HTMLElement) => {
    state.viewerBackgroundRef = el
  },
  GET_VIEWPORT: (state: IState, el: HTMLElement) => {
    state.viewportRef = el
  },
  SET_PAGE_ATTRIBUTE: (state: IState, data: any) => {
    const { attribute, value } = data
    state.mainJson.body.style[attribute] = data
  },
  SET_COMPONENT_CONFIG: (state: IState, config: IComponentConfig) => {
    state.componentConfig = config
  },
  // SET_PAGE_SIZE: (state: IState, data: IPageSize) => {
  //   state.pageSize = data
  // },
  SET_DARK_MODE: (state: IState, data: any) => {
    state.mainJson.isDark = data
  },
  SET_PAGE_CUSTOM_CLASS: (state: IState, style: any) => {
    state.mainJson.body.style.customStyle = style
  }
}

const actions = {
  setMainJsonAttribute({ commit, state, dispatch }, data: any) {
    // const { id, path, value } = data
    // if (!id || !path) return
    // const oldValue = _.get(state.mainJson.body.children.find(item => item.id === id), path)
    // if (oldValue === value) return
    // if (JSON.stringify(oldValue) === JSON.stringify(value)) {
    //   console.log('新旧值相等')
    //   return
    // }

    commit('SET_MAIN_JSON_ATTRIBUTE', data)
    if (data.path[0] == 'api' || data.path[0] == 'action') dispatch('savePageConfig', data)   //暂时去掉，测试一下

    saveLocal.setItem('mainjson', JSON.stringify(state.mainJson)).then(() => {
      commit('SET_COMPONENT_LIST', state.mainJson)
    }).catch(err => {
      console.log('err', err)
    })
  },
  setMainJsonStyle({ commit, state, dispatch }, data: any) {
    commit('SET_MAIN_JSON_STYLE', data)
    saveLocal.setItem('mainjson', JSON.stringify(state.mainJson))
  },
  savePageConfig({ commit, state }, data: any) {
    const { id, path, value } = data
    if (!path.includes('option')) return
    const targetComponent = state.mainJson.body.children.find(item => item.id === id)
    const component = {}
    for (let key of Object.keys(targetComponent)) {
      if (!['option', 'api', 'staticData', 'action'].includes(key)) {
        component[key] = targetComponent[key]
      }
    }
    console.log(component)
    const params = [{
      controlName: '',
      controlType: targetComponent.type,
      jsonConfig: JSON.stringify(component),
      jsonData: JSON.stringify(targetComponent.staticData),
      controlCode: targetComponent.id,
      pageId: state.mainJson.pageID
    }]
    API.pageManage.savePageConfig(params).then((res) => {
    })
  },

  savePage({ commit, state }, data: any) {
    const { id, path, value } = data

    API.pageManage.savePage(id, data).then((res) => {
    })
  },

  //保存 main-json 到 vuex 保存componentsList
  setMainJson({ commit }, value) {
    commit('SET_MAIN_JSON', value)
    commit('SET_COMPONENT_LIST', value)
  },
  // 保存main-json 到 localStorage
  saveMainJson({ commit, dispatch }, value) {
    console.log('saveMainJson')
    saveLocal.setItem('mainjson', JSON.stringify(value)).then(() => {
      dispatch('setMainJson', value)
    }).catch(err => {
      console.log('err', err)
    })
  },
  setDarkMode({ commit }, value) {
    commit('SET_DARK_MODE', value)
    saveLocal.setItem('mainjson', state.mainJson)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
