export const DataViewColumns = [
  {
    title: '页面名称',
    dataIndex: 'pageName',
    key: 'pageName',
    width: '130px',
    ellipsis: true,
  },
  {
    title: 'pageName',
    dataIndex: 'pageEname',
    key: 'pageName',
    width: '100px',
    ellipsis: true,
  },
  {
    title: '页面标签',
    dataIndex: 'groupName',
    key: 'groupName',
    width: '120px',
    ellipsis: true,
  },
  {
    title: '页面连接',
    dataIndex: 'pageUrl',
    key: 'pageUrl',
    width: '230px',
    // ellipsis: true
  },
  {
    title: '发布时间',
    dataIndex: 'publishTime',
    key: 'publishTime',
    width: '170px',
  },
  {
    title: '发布状态',
    dataIndex: 'status',
    key: 'status',
    width: '100px',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '160px',
  },
]

export const DataSourceColumns = [
  {
    title: '名称',
    dataIndex: 'resourceName',
    key: 'resourceName',
    width: '130px',
  },
  {
    title: '基础类型',
    dataIndex: 'resourceType',
    key: 'resourceType',
    width: '130px',
  },
  {
    title: '数据源类型',
    dataIndex: 'databaseType',
    key: 'databaseType',
    width: '130px',
  },
  {
    title: '编号',
    dataIndex: 'dataResourceCode',
    key: 'dataResourceCode',
    width: '130px',
  },
  {
    title: 'IP',
    dataIndex: 'host',
    key: 'host',
    width: '130px',
  },
  {
    title: '端口',
    dataIndex: 'port',
    key: 'port',
    width: '100px',
  },
  {
    title: '数据库',
    dataIndex: 'dbName',
    key: 'dbName',
    width: '100px',
  },
  {
    title: '用户名',
    dataIndex: 'loginUserName',
    key: 'loginUserName',
    width: '100px',
  },
  {
    title: '描述',
    dataIndex: 'remark',
    key: 'remark',
    width: '100px',
    ellipsis: true,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: '100px',
    ellipsis: true,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '130px',
  },
]
