import { cloneDeep } from 'lodash'

export const siderLeftPx = 70 // 左边菜单栏得宽度
export const siderRightPx = 300 // 右边菜单栏得宽度
export const editorHeaderPx = 40 // 导航栏高度
export const rulersWidthPx = 30 // 卡尺宽度
export const rulersHeightPx = 30 // 卡尺高度
export const centerBtnPx = 30 // 居中按钮宽高

export const DYNC_BI_CODE = '2'
export const QUERY_BI_CODE = '3'
export const DYNC_BI = 'DYNCBI'
export const QUERY_BI = 'QUERYBI'
export const PORT_API = '1'
export const PORT_DB = '0'
export const RELEASE_SUCCESS = '1'
export const RELEASE_ERROR = '0'

export const SUCCESS = '1'
export const ERROR = '0'

// 场景 dyncBI === 2 queryBI ===3
export const biType = {
  [DYNC_BI_CODE]: DYNC_BI,
  [QUERY_BI_CODE]: QUERY_BI,
}

export const TYPE_WITH_REQUEST = ['api', 'auto', 'mod']

export const conversion = {
  '1': 'MySql',
  '2': 'SqlServer',
  '3': 'MQ',
}
export const conversionDatabaseType={
  '1':"常规类型",
  '2':'idc类型'
}
export const siderLeftTab = [
  { id: 1, type: 'line', name: '折线图', iconFont: 'icon-zhexiantu' },
  { id: 2, type: 'bar', name: '柱状图', iconFont: 'icon-zhuzhuangtu02-01' },
  { id: 3, type: 'pie', name: '饼图', iconFont: 'icon-bingtu' },
  { id: 4, type: 'table', name: '表格', iconFont: 'icon-biaoge' },
  { id: 5, type: 'text', name: '文本', iconFont: 'icon-text' },
  { id: 6, type: 'form', name: '表单', iconFont: 'icon-form' },
  { id: 7, type: 'image', name: '图片', iconFont: 'icon-tupian' },
]

export const interfaceState = [
  {
    value: PORT_API,
    label: 'API',
  },
  {
    value: PORT_DB,
    label: 'DB',
  },
]

export const ParamsType = [
  {
    value: 'string',
    label: 'string',
  },
  {
    value: 'int',
    label: 'int',
  },
  {
    value: 'array',
    label: 'array',
  },
  {
    value: 'object',
    label: 'object',
  },
  {
    value: 'integer',
    label: 'integer',
  },
]

export const releaseState = [
  {
    value: RELEASE_ERROR,
    label: '未发布',
  },
  {
    value: RELEASE_SUCCESS,
    label: '已发布',
  },
]

export const ITERATION_STATE = { [ERROR]: '未发布', [SUCCESS]: '已发布' }
export const INTERFACE_STATE = { [PORT_API]: 'API', [PORT_DB]: 'DB' }

export const serverType = [
  {
    label: 'API',
    value: PORT_API,
  },
  {
    label: 'DB',
    value: PORT_DB,
  },
]

export const usableState = {
  false: '否',
  true: '是',
}

export const setColor = (index: number) => {
  // let _color = ["#1dd3d4", "#178484", "#227474"];
  // let _color = ["#1dd3d4", "#146cc0", "#105292", "#137cd4", "#dd6de2"];
  let _color = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
  return _color[index]
}

export const ColorLuminance = (hex, lum) => {
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0
  var rgb = '#',
    c,
    i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

//series 序列化
export const seriesOption = (option, datas, type?) => {
  if (option.series && option.series[0]?.type === 'pie') return option
  if (option?.type === 'chart-pie3D' || option?.type === 'chart-chinaMap') return option
  // console.log('option.seriesType',option.seriesType)
  // if (option.type == 'chart-pie3D') return getPie3D(eachData(datas.source),2)
  let series = []
  let _series = option.series[0]
  const protoOptionLength = option.series.length

  let _datasLength
  if (option.series[0]?.seriesLayoutBy != undefined) {
    _datasLength = datas.source?.length - 1
  } else {
    _datasLength = datas.source[0]?.length - 1
  }
  console.log(option.series, datas, _datasLength, "序列化--------------------")
  if (protoOptionLength > 1 && option.series[0]?.stack) {
    if(_datasLength == 2){
      // 如果柱状图序列只有一条柱状图 默认截取第一条
      if (option.series[0]?.type == 'bar') {
        option.series = option.series.splice(0, 1)
        return option
      }
    }else{
      const isEvery = option.series.every(v => option.series[0].type == v.type)
      if (isEvery) return option
    }
  }
  if (_datasLength > 1 || protoOptionLength == 1) {
    // 初始化 series 有颜色序列 就 return option 出去  如果是在编辑 MOCK 数据 走原有逻辑
    if (type == 'edit' || !option.series[0]?.itemStyle?.color?.colorStops?.length) {
      option.series.length = _datasLength
      for (let i = 0; i < _datasLength; i++) {
        let _seriesItem = cloneDeep(_series)
        if (_seriesItem?.encode) {
          _seriesItem.encode = {
            z: datas.source[0][i + 1],
          }
        }
        if (option.series[0]?.color) {
          _seriesItem.color = setColor(i)
        }
        if (typeof option.series[0]?.itemStyle?.color === 'object') {
          _seriesItem.itemStyle.color.colorStops.length = 0
          _seriesItem.itemStyle.color.colorStops[0] = {
            offset: 0,
            color: setColor(i),
          }
          _seriesItem.itemStyle.color.colorStops[1] = {
            offset: 1,
            color: ColorLuminance(setColor(i), 0.5),
          }
        } else {
          if (_seriesItem?.itemStyle?.color) _seriesItem.itemStyle.color = setColor(i)
        }
        series.push(_seriesItem)
      }
      option.series = series
      return option
    } else {
      return option
    }
  } else {
    if (option.series[0]?.type == 'liquidFill') {
      option.series[0].data = datas.source[0]
      delete option.series[0].dataset
    }
    // 如果柱状图序列只有一条柱状图 默认截取第一条
    if (option.series[0]?.type == 'bar') {
      option.series = option.series.splice(0, 1)
    }
    return option
  }
}

const eachData = data => {
  let itemData = []
  data.forEach(item => {
    itemData.push({
      name: item[0],
      value: item[1],
      itemStyle: {
        opacity: 1,
      },
    })
  })
  return itemData
}

const getParametricEquation = (startRatio, endRatio, isSelected, isHovered, k, h) => {
  // 计算
  let midRatio = (startRatio + endRatio) / 2
  let startRadian = startRatio * Math.PI * 2
  let endRadian = endRatio * Math.PI * 2
  let midRadian = midRatio * Math.PI * 2

  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false
  }

  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3

  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0

  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1

  // 返回曲面参数方程
  return {
    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 42,
    },

    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 24,
    },

    x: function (u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
      }
      if (u > endRadian) {
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
    },

    y: function (u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
      }
      if (u > endRadian) {
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
    },

    z: function (u, v) {
      if (u < -Math.PI * 0.5) {
        return -Math.sin(u)
      }
      if (u > Math.PI * 2.5) {
        return 0
      }
      return Math.sin(v) > 0 ? 1 * h : -1
    },
  }
}

// 生成模拟 3D 饼图的配置项
const getPie3D = (pieData, internalDiameterRatio) => {
  let series = []
  let sumValue = 0
  let startValue = 0
  let endValue = 0
  let legendData = []
  let k = typeof internalDiameterRatio !== 'undefined' ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio) : 1 / 3

  // 为每一个饼图数据，生成一个 series-surface 配置
  for (let i = 0; i < pieData.length; i++) {
    sumValue += pieData[i].value

    let seriesItem = {
      name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
      type: 'surface',
      parametric: true,
      wireframe: {
        show: false,
      },
      shading: 'lambert',
      pieData: pieData[i],
      pieStatus: {
        selected: false,
        hovered: false,
        k: k,
      },
    }

    if (typeof pieData[i].itemStyle != 'undefined') {
      let itemStyle = {}

      typeof pieData[i].itemStyle.color != 'undefined' ? (itemStyle['color'] = pieData[i].itemStyle.color) : null
      typeof pieData[i].itemStyle.opacity != 'undefined' ? (itemStyle['opacity'] = pieData[i].itemStyle.opacity) : null

      seriesItem['itemStyle'] = itemStyle
    }
    series.push(seriesItem)
  }

  // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
  // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
  for (let i = 0; i < series.length; i++) {
    endValue = startValue + series[i].pieData.value

    series[i].pieData.startRatio = startValue / sumValue
    series[i].pieData.endRatio = endValue / sumValue
    series[i].parametricEquation = getParametricEquation(
      series[i].pieData.startRatio,
      series[i].pieData.endRatio,
      false,
      false,
      k,
      series[i].pieData.value
    )

    startValue = endValue

    legendData.push(series[i].name)
  }

  // 准备待返回的配置项，把准备好的 legendData、series 传入。
  let option = {
    //animation: false,
    type: 'chart-pie3D',
    backgroundColor: 'transparent',
    legend: {
      show: false,
      data: legendData,
    },
    tooltip: {
      formatter: params => {
        if (params.seriesName !== 'mouseoutSeries') {
          return `${params.seriesName
            }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color
            };"></span>${option.series[params.seriesIndex].pieData.value}`
        }
      },
    },
    xAxis3D: {
      min: -1,
      max: 1,
    },
    yAxis3D: {
      min: -1,
      max: 1,
    },
    zAxis3D: {
      min: -1,
      max: 1,
    },
    grid3D: {
      show: false,
      boxHeight: 10,
      //top: '30%',
      bottom: '50%',
      shading: 'lambert',
      groundPlane: {
        show: true,
        color: '#999',
      },

      light: {
        main: {
          intensity: 1,
          shadow: true,
          shadowQuality: 'high',
          alpha: 30,
        },
      },
      viewControl: {
        distance: 300,
        alpha: 25,
        beta: 45,
      },
    },
    series: series,
  }
  return option
}
